import React, { useContext, useRef, useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import cn from 'classnames'
import { AuthContext } from '../../context/AuthContext';
import logo from '../.././images/logo.png';
import profile from '../.././images/icons/User Red.svg';
import arrowdown from '../.././images/icons/Chevron-down.svg';
import detail from '../.././images/icons/File.svg';
import lock from '../.././images/icons/Lock.svg';
import logoutIcon from '../.././images/icons/Sign Out.svg';
import billing from '../.././images/icons/Billing.svg';
import support from '../.././images/icons/chat 2.svg';
import listenForOutsideClick from "../../common/listen-for-outside-clicks";
import './Navbar.css';

export const Navbar = () => {
  const navigate = useNavigate();
  const {user, logout, planPaused, dateExpired } = useContext(AuthContext);

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenForOutsideClick(listening, setListening, menuRef, setIsOpen));
  
  const logoutHandler = event => {
    event.preventDefault()
    logout()
    navigate('/')
  }

  return (
    <header className='header'>
      <div className='nav-logo-card'>
        <img className='nav-logo' src={logo} alt='Logo'></img>
      </div>

      {/* <div className='header-beta'>
        <span>Version: Beta (Limited Release)</span>
      </div> */}

      <nav ref={menuRef} className="navbar">
        <div className="navbar-user" onClick={toggle}>
          {user?.id &&
            <>
              <div className="navbar-profile navbar-profile-google">
                {user.userPhoto
                  ? <img className="navbar-profile-img" src={user.userPhoto} alt='profile'></img>
                  : <img src={profile} alt='profile'></img>}
              </div>
              <div className='nav-current-profile'>
                {user.paymentPlanName &&
                  <div
                    className={cn('nav-current-plan', {
                      'nav-current-plan-free': user.paymentPlanId === 1,
                      'nav-current-plan-small-b': user.paymentPlanId === 2,
                      'nav-current-plan-pro': user.paymentPlanId === 3,
                      'nav-current-plan-enterprise': user.paymentPlanId === 4,
                      'nav-current-plan-expired': dateExpired
                    })}
                  >
                    {!dateExpired
                      ? <>
                        <span className={cn({ 'nav-current-plan-paused': planPaused })}>
                          {user.paymentPlanName.toUpperCase()}
                        </span>
                        {planPaused && <span> (Paused)</span>}
                      </>
                      : <span className={cn({ 'nav-current-plan-paused': planPaused })}>EXPIRED</span>}
                  </div>
                }
                <div className='navbar-email'>{user.email}</div>
              </div>
            </>
          }
          <button className='button-arrow'>
            {!isOpen
              ? <img src={arrowdown} alt='arrow down'></img>
              : <img style={{'transform': 'rotate(180deg)'}} src={arrowdown} alt='arrow up'></img>}
          </button>
        </div>
        
        {isOpen
          ?
          <ul className="nav-list" onClick={toggle}>
            <li>
              <NavLink
                to="/back_office/user/details"
                className='nav-link'
              >
                <img src={detail} alt='details' className='nav-link-img nav-link-img--1'></img>
                Account Administration
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/back_office/user/membership_plan"
                className={cn('nav-link', {
                  'not-owner-item--disabled': user?.role !== 'owner' && user?.role !== 'tenant-admin'})}
              >
                <img src={billing} alt='billing' className='nav-link-img nav-link-img--4'></img>
                Membership plan
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/back_office/user/change_password"
                className='nav-link'
              >
                <img src={lock} alt='lock' className='nav-link-img nav-link-img--2'></img>
                Change password
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/back_office/user/customer_support"
                className='nav-link'
              >
                <img src={support} alt='lock' className='nav-link-img nav-link-img--5'></img>
                Customer support
              </NavLink>
            </li>
            <li>
              <a href="/back_office" onClick={logoutHandler} className='nav-link'>
              <img src={logoutIcon} alt='logout' className='nav-link-img nav-link-img--3'></img>
                Log out
              </a>
            </li>
          </ul>
          : null
        }
      </nav>
    </header>
  )
}
