import React, { useState, useCallback, useEffect, useContext } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import { ChartBarMailingHistory } from './ChartBarMailingHistory';
import { useCrypto } from '../../hooks/crypto.hook';
import colourStyles from '../../styles/colour-style';
import Select, { components } from "react-select";
import ReactSelect from '../ReactSelect/ReactSelect';
import { AllUsersOption } from '../ReactSelect/ReactSelectOption';
import './MailingHistory.css';

export const MailingHistoryChart = () => {
  const { secondaryLoading, requestWithSecondaryLoading } = useHttp();
  const { token } = useAuth();
  const { encryptData, decryptData } = useCrypto();
  const { allUsers } = useContext(AuthContext);

  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState({ value: 'all', label: "All", id: 0, syncType: null });
  const [chartData, setChartData] = useState({labels: [], datasets: []});
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [minDate, setMinDate] = useState(moment('2022-01-01').format('YYYY-MM-DD'));
  const [rangeValue, setRangeValue] = useState(31);
  const [sumMetric, setSumMetric] = useState({
    'allEmails': 0,
    'introductionEmails': 0,
    'phishingEmails': 0,
    'trainingEmails': 0,
    'congratsWarningEmails': 0,
    'administrativeEmails': 0,
    'monthlyUpdateEmails': 0
  });
  const [selectedUser, setSelectedUser] = useState(null);

  const maxDate = moment().format('YYYY-MM-DD');

  const fetchData = useCallback(async (startDate, endDate, selectedTenant, selectedUser) => {
    try {
      if (token) {
        const data = encryptData({ startDate, endDate, selectedTenant, selectedUser });
        const fetched = await requestWithSecondaryLoading(`/back_office/api/user/sync_mailing_history`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })

        const decryptHistory = decryptData(fetched);
        setChartData(decryptHistory.chartData);
        setSumMetric(decryptHistory.sumMetric);
        setMinDate(decryptHistory.minDate);

        return decryptHistory
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, requestWithSecondaryLoading]);

  useEffect(() => {
    const usersTenants = allUsers.map(el => ({
      tenantName: el.tenantName,
      syncType: el.syncType
    }));
    const uniqueObjects = [...new Set(usersTenants.map(JSON.stringify))].map(JSON.parse).sort((a, b) => a.tenantName.localeCompare(b.tenantName));
    const selectTenantArray = [{tenantName: 'All', syncType: null}, ...uniqueObjects]
    const tenantList = selectTenantArray.map((v, i) => { return { value: v?.tenantName?.toLowerCase(), label: v?.tenantName, id: i, tenant: true, syncType: v?.syncType } })

    setTenants(tenantList)
  }, [allUsers])

  const Option = (props) => {
    const { label, value, caption, tenant, syncType } = props.data;

    return (
      <components.Option {...props}>
         <div className={cn(`syncTypes-label-${value.toString().split(' ').join('-')} syncTypes-label-${syncType}`,{
            'users-list-tenant-label': tenant
          })}
        >
          {label} {syncType ? `(${syncType})` : ''}
        </div>
        <div className='caption'>{caption}</div>
      </components.Option>
    );
  };

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setSelectedUser(null);
  };

  useEffect(() => {
    if (startDate && endDate) fetchData(startDate, endDate, selectedTenant, selectedUser);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, fetchData, selectedTenant, selectedUser]);

  if (rangeValue < 1) setRangeValue(1);
  if (rangeValue > 31) setRangeValue(31);

  const allUsersFilteredByTenant = allUsers.filter(user => selectedTenant.label !== 'All'
    ? user.tenantName === selectedTenant.label && user.syncType === selectedTenant.syncType
    : user
  );

  const allUsersOptionList = allUsersFilteredByTenant?.map(user => (
    { value: user.id, label: user.email, caption: user.firstName + ' ' + user.secondName }
  ))

  const handleUserChange = (data) => {
    setSelectedUser(data);
  };

  useEffect(() => {
    setRangeValue(moment(endDate).diff(moment(startDate), 'days') + 1);
  }, [startDate, endDate]);

  return (
    <div className={cn({'history-chart-loading': secondaryLoading})}>
      <div className='history-card'>
        <div className="input-container-history-title">
          <h3>Mailing</h3>
          <div className="input-container-history">
            <p>Tenant:</p>
            <div className={`input-field-history-tenant input-field-tenant-${selectedTenant.syncType}`}>
              <Select
                id='select'
                options={tenants}
                components={{ Option }}
                placeholder="Select tenant"
                className="select-edit-history"
                value={selectedTenant}
                onChange={handleTenantChange}
                isSearchable={true}
                styles={colourStyles}
                isDisabled={secondaryLoading}
              />
            </div>
            <p>Select date range:</p>
            <div className="input-field-history">
              <div className='input-field-history-close--date'></div>
              <input
                required
                id="startDate"
                type="date"
                name="startDate"
                value={startDate}
                placeholder="Enter Date"
                className={cn("input-edit-history", {"input-edit-history-loading": secondaryLoading})}
                min={minDate}
                max={moment(endDate).format('YYYY-MM-DD')}
                onChange={(e) => {
                  if (e.target.value) {
                    setStartDate(e.target.value);
                    if (moment(endDate).diff(moment(e.target.value), 'days') > 30) {
                      setEndDate(moment(e.target.value).add(30, 'days').format('YYYY-MM-DD'))
                    }
                  }
                }}
                disabled={secondaryLoading}
              />
            </div>
            <div className="input-field-history">
              <div className='input-field-history-close--date'></div>
              <input
                required
                id="endDate"
                type="date"
                name="endDate"
                placeholder="Enter Date"
                className={cn("input-edit-history", {"input-edit-history-loading": secondaryLoading})}
                value={endDate}
                min={moment(minDate).format('YYYY-MM-DD')}
                max={maxDate}
                onChange={(e) => {
                  if (e.target.value) {
                    setEndDate(e.target.value);
                    setStartDate(moment(e.target.value).subtract(rangeValue - 1, 'days').format('YYYY-MM-DD'));
                  }
                }}
                disabled={secondaryLoading}
              />
            </div>
            <p>Show days:</p>
            <div className="input-field-history input-field-range-history">
              <input
                required
                id="start-history"
                type="number"
                min={1}
                max={31}
                step={1}
                value={rangeValue}
                className={cn("input-edit-history", {"input-edit-history-loading": secondaryLoading})}
                onChange={(e) => {
                  setRangeValue(e.target.value);
                  setTimeout(() => {
                    setStartDate(moment(endDate).subtract(e.target.value - 1, 'days').format('YYYY-MM-DD'))
                  }, 600)
                }}
                disabled={secondaryLoading}
              />
            </div>
          </div>
        </div>

        <div className='metric-container'>
          <div className='sum-metric'>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                All Emails
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.allEmails}</div>
            </div>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                Introduction Emails
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.introductionEmails}</div>
            </div>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                Phishing Emails
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.phishingEmails}</div>
            </div>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                Training Emails
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.trainingEmails}</div>
            </div>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                Congrats/warning Emails
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.congratsWarningEmails}</div>
            </div>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                Administrative Emails
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.administrativeEmails}</div>
            </div>
            <div className='sum-metric-item'>
              <div className='sum-metric-label'>
                Monthly update Emails
              </div>
              <div className={cn('sum-metric-value', {'sum-metric-value-download': secondaryLoading})}>{sumMetric.monthlyUpdateEmails}</div>
            </div>
          </div>

          <div className={'all-users-select'}>
            <label className={cn('userslist-select-label', {"input-edit-history-loading": secondaryLoading})}>
              <ReactSelect
                classNames={'multiselect-user'}
                options={ allUsersOptionList }
                optionComponent={ AllUsersOption }
                placeholder={'User...'}
                onChange={handleUserChange}
                value={selectedUser}
                isSearchable={true}
                isClearable={true}
                isDisabled={secondaryLoading}
                optionHeight={50}
                rows={6}
              />
            </label>
          </div>
        </div>

        <div className={cn('chart-metric', {'chart-metric-download': secondaryLoading})}>
          <ChartBarMailingHistory chartData={chartData}/>
        </div>
      </div>
    </div>
  )
}
