/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Loader } from '../Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import ReactSelect from '../ReactSelect/ReactSelect';
import { DepartmentOption, LocationOption, TenantOption } from '../ReactSelect/ReactSelectOption';
import { AnalyticsGeneralContext } from '../../context/AnalyticsGeneralContext';
import { AuthContext } from '../../context/AuthContext';
import './UserAnalytics.css';
import { CustomizedTooltipDateRange } from '../Tooltips/Tooltip';

const ExecutiveSummary = () => {
  const {
    tenants: contextTenants,
    departments: contextDepartments,
    locations: contextLocations,
    minMailingDate: contextMinMailingDate
  } = useContext(AnalyticsGeneralContext);
  const { token } = useContext(AuthContext);
  const { request, loading } = useHttp();
  const { encryptData, decryptData } = useCrypto();

  const [tenants, setTenants] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [minMailingDate, setMinMailingDate] = useState(null);
  const [filteredMinMailingDate, setFilteredMinMailingDate] = useState(null);
  const [summaryData, summaryrData] = useState({});
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [minMailingDateSelected, setMinMailingDateSelected] = useState(false);

  useEffect(() => {
    setTenants(contextTenants);
    setDepartments(contextDepartments);
    setLocations(contextLocations);
    setMinMailingDate(contextMinMailingDate);
  }, [contextDepartments, contextLocations, contextTenants, contextMinMailingDate]);

  const fetchSummaryData = useCallback(async () => {
    try {
      if (token && minMailingDate) {
        const data = encryptData({
          department: selectedDepartment ? selectedDepartment.label : null,
          location: selectedLocation ? selectedLocation.label : null,
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate
        });
        const responseSummaryData = await request('/back_office/api/analytics/summary', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptSummaryData = decryptData(responseSummaryData);
        summaryrData(decryptSummaryData);

        setFilteredMinMailingDate(decryptSummaryData.filteredMinMailingDate);

        let filteredTenants = contextTenants;
        if (selectedDepartment || selectedLocation) {
          filteredTenants = filteredTenants?.filter(tenant =>
            decryptSummaryData?.tenantIds?.includes(tenant.id)
          );
        }

        let filteredDepartments = contextDepartments;
        if (selectedTenant || selectedLocation) {
          filteredDepartments = filteredDepartments?.filter(department =>
            decryptSummaryData?.departments?.includes(department.label)
          );
        }

        let filteredLocations = contextLocations;
        if (selectedTenant || selectedDepartment) {
          filteredLocations = filteredLocations?.filter(location =>
            decryptSummaryData?.locations?.includes(location.label)
          );
        }

        contextTenants?.length && setTenants(filteredTenants);
        contextDepartments?.length && setDepartments(filteredDepartments);
        contextLocations?.length && setLocations(filteredLocations);

        return decryptSummaryData;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedTenant, selectedDepartment, selectedLocation, minMailingDate]);

  useEffect(() => {
    fetchSummaryData();
  }, [fetchSummaryData]);

  const handleCheckedCheckbox = (date, days) => moment().diff(date, 'd') === days;
  const handleDisabledCheckbox = (date, days) => moment().diff(date || moment(), 'd') < days;
  const handleCheckboxChange = days => {
    setMinMailingDate(moment().subtract(days, 'd').format('YYYY-MM-DD'));
    setMinMailingDateSelected(true);
  };

  const handleAllTimeChange = () => {
    setMinMailingDate(contextMinMailingDate);
    setMinMailingDateSelected(false);
  };

  const handleDepartmentChange = (data) => {
    setSelectedDepartment(data);
    handleAllTimeChange();
  };

  const handleLocationChange = (data) => {
    setSelectedLocation(data);
    handleAllTimeChange();
  };

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    handleAllTimeChange();
  };

  return (
    <>
      <div className='user-analytics-card user-analytics-card-summary'>
        <div className='user-analytics-input'>
          <div className={cn('input-container-analytics-item', { 'input-container-analytics-item--disabled': loading })}>
            <div className='executive-input-field-analytics-tenant'>
              <label>
                <ReactSelect
                  options={tenants}
                  optionComponent={TenantOption}
                  placeholder={'Select Tenant...'}
                  classNames={'option-select-default'}
                  value={selectedTenant}
                  onChange={handleTenantChange}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={loading}
                  optionHeight={32}
                  rows={10}
                />
              </label>
            </div>
          </div>
          <div className={cn('input-container-analytics-item', { 'input-container-analytics-item--disabled': loading })}>
            <div className='executive-input-field-analytics-department'>
              <label className={'department-select'}>
                <ReactSelect
                  options={departments}
                  optionComponent={DepartmentOption}
                  placeholder={'Select Department...'}
                  classNames={'option-select-default'}
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={loading}
                  optionHeight={32}
                  rows={10}
                />
              </label>
            </div>
          </div>
          <div className={cn('input-container-analytics-item', { 'input-container-analytics-item--disabled': loading })}>
            <div className='executive-input-field-analytics-location'>
              <label className={'location-select'}>
                <ReactSelect
                  options={locations}
                  optionComponent={LocationOption}
                  placeholder={'Select Location...'}
                  classNames={'option-select-default'}
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={loading}
                  optionHeight={32}
                  rows={10}
                />
            </label>
            </div>
          </div>
        </div>
        <div className='summary-checkbox-container'>
          <div className='summary-checkbox-label'>
           Analytics for:
          </div>

          <div
            role='button'
            onClick={handleAllTimeChange}
            className={cn('summary-checkbox-label', {
              'summary-checkbox-label--disabled': loading
            })}
          >
            <label>All time</label>
            <input
              type="checkbox"
              className="summary-checkbox"
              checked={!minMailingDateSelected}
              disabled={loading}
            />
          </div>

          <CustomizedTooltipDateRange
            text1={'Analytics for this period'}
            text2={'are not yet available'}
            showFragment={!loading && handleDisabledCheckbox(filteredMinMailingDate, 30)}
            position={'top'}
            component={
              <div
                role='button'
                onClick={() => !loading && !handleDisabledCheckbox(filteredMinMailingDate, 30) ? handleCheckboxChange(30) : null}
                className={cn('summary-checkbox-label', {
                  'summary-checkbox-label--disabled': loading || handleDisabledCheckbox(filteredMinMailingDate, 30)
                })}
              >
                <label>30 Days</label>
                <input
                  type="checkbox"
                  className="summary-checkbox"
                  checked={handleCheckedCheckbox(minMailingDate, 30)}
                  disabled={loading || handleDisabledCheckbox(filteredMinMailingDate, 30)}
                />
              </div>
            }
          />

          <CustomizedTooltipDateRange
            text1={'Analytics for this period'}
            text2={'are not yet available'}
            showFragment={!loading && handleDisabledCheckbox(filteredMinMailingDate, 90)}
            position={'top'}
            component={
              <div
                role='button'
                onClick={() => !loading && !handleDisabledCheckbox(filteredMinMailingDate, 90) ? handleCheckboxChange(90) : null}
                className={cn('summary-checkbox-label', {
                  'summary-checkbox-label--disabled': loading || handleDisabledCheckbox(filteredMinMailingDate, 90)
                })}
              >
                <label>90 Days</label>
                <input
                  type="checkbox"
                  className="summary-checkbox"
                  checked={handleCheckedCheckbox(minMailingDate, 90)}
                  disabled={loading || handleDisabledCheckbox(filteredMinMailingDate, 90)}
                />
              </div>
            }
          />

          <CustomizedTooltipDateRange
            text1={'Analytics for this period'}
            text2={'are not yet available'}
            showFragment={!loading && handleDisabledCheckbox(filteredMinMailingDate, 180)}
            position={'top'}
            component={
              <div
                role='button'
                onClick={() => !loading && !handleDisabledCheckbox(filteredMinMailingDate, 180) ? handleCheckboxChange(180) : null}
                className={cn('summary-checkbox-label', {
                  'summary-checkbox-label--disabled': loading || handleDisabledCheckbox(filteredMinMailingDate, 180)
                })}
              >
                <label>180 Days</label>
                <input
                  type="checkbox"
                  className="summary-checkbox"
                  checked={handleCheckedCheckbox(minMailingDate, 180)}
                  disabled={loading || handleDisabledCheckbox(filteredMinMailingDate, 180)}
                />
              </div>
            }
          />

          <CustomizedTooltipDateRange
            text1={'Analytics for this period'}
            text2={'are not yet available'}
            showFragment={!loading && handleDisabledCheckbox(filteredMinMailingDate, 365)}
            position={'top'}
            component={
              <div
                role='button'
                onClick={() => !loading && !handleDisabledCheckbox(filteredMinMailingDate, 365) ? handleCheckboxChange(365) : null}
                className={cn('summary-checkbox-label', {
                  'summary-checkbox-label--disabled': loading || handleDisabledCheckbox(filteredMinMailingDate, 365)
                })}
              >
                <label>365 Days</label>
                <input
                  type="checkbox"
                  className="summary-checkbox"
                  checked={handleCheckedCheckbox(minMailingDate, 365)}
                  disabled={loading || handleDisabledCheckbox(filteredMinMailingDate, 365)}
                />
              </div>
            }
          />
        </div>
      </div>

      {!loading
        ? !Object.keys(summaryData)?.length
          ? <div className='user-analytics-card'>
              <p className='analytics-description'>
                There are no data yet.
              </p>
            </div>
          : <div className='user-analytics-raw'>
              <div className='user-analytics-mini-card'>
                <h3>Phishing</h3>
                <div className='user-analytics-text'>
                  <div className='user-analytics-text-item'>
                    <div className='education-text-item-title'>Total Phishing Emails Sent:</div>
                    <div className='phishing-text-item-value'>{summaryData?.Total_Phishing_Emails_Sent?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</div>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='education-text-item-title'>Phishing Click Count:</div>
                    <div className='phishing-text-item-value'>{summaryData?.Phishing_Click_Count?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</div>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='education-text-item-title'>Phish Click Rate:</div>
                    <div className='phishing-text-item-value'>{summaryData?.Phish_Click_Rate || 0}%</div>
                  </div>
                  <br />
                  <div className='user-analytics-text-item'>
                    <div className='education-text-item-title'>High Risk User Count:</div>
                    <div className='phishing-text-item-value'>{summaryData?.High_Risk_User_Count?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</div>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='education-text-item-title'>Moderate Risk User Count:</div>
                    <div className='phishing-text-item-value'>{summaryData?.Moderate_Risk_User_Count?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</div>
                  </div>
                  <br />
                  <div className='user-analytics-text-item'>
                    <div className='education-text-item-title'>Reported Phish Count:</div>
                    <div className='phishing-text-item-value'>{summaryData?.Reported_Phish_Count?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</div>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='education-text-item-title'>Accurately Reported Phish Count:</div>
                    <div className='phishing-text-item-value'>{summaryData?.Accurately_Reported_Phish_Count?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</div>
                  </div>
                </div>
              </div>

              <div className='user-analytics-mini-card'>
                <h3>Education</h3>
                <div className='user-analytics-text'>
                    <div className='user-analytics-text-item'>
                      <div className='education-text-item-title'>Total Educational Emails Sent:</div>
                      <div className='education-text-item-value'>{summaryData?.Total_Educational_Emails_Sent?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</div>
                    </div>
                    <div className='user-analytics-text-item'>
                      <div className='education-text-item-title'>Educational Interactions:</div>
                      <div className='education-text-item-value'>{summaryData?.Educational_Interactions?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}</div>
                    </div>
                    <div className='user-analytics-text-item'>
                      <div className='education-text-item-title'>Engagement Rate:</div>
                      <div className='education-text-item-value'>{summaryData?.Engagement_Rate|| 0}%</div>
                    </div>
                </div>
              </div>

              {/* <div className='user-analytics-mini-card'>
                <h3>Security Awareness Grade</h3>
              </div> */}
            </div>
        : <Loader />
      }
    </>
  );
};

export { ExecutiveSummary };
