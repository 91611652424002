import React, { useState, useCallback, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import moment from "moment";
import Select, { components } from "react-select";
import { Loader } from '../../components/Loader/Loader';
import { useParams, useLocation } from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';
import { useTitle } from '../../hooks/title.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { useSortableData } from '../../hooks/sort.hook';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import {
  customModalStyles, maxFastrackSendingDuration, maxNextEmailDelay,
  maxResendingEmailCount, minFastrackSendingDuration, minNextEmailDelay,
  minResendingEmailCount, validateDateScheduling
} from '../../config/config';
import colourStyles from '../../styles/colour-style';
import { AuthContext } from '../../context/AuthContext';
import { MandatoryTrainingUsers } from './MandatoryTrainingUsers';
import { CustomizedTooltipInfo } from '../../components/Tooltips/Tooltip';
import './Categories.css';

export const Category = () => {
  const { category } = useParams();
  useTitle(`PhishFirewall | ${category}`);

  const { loading, request } = useHttp();
  const location = useLocation();
  const { state } = location;
  const { token } = useAuth();
  const { showToastMessage, user } = useContext(AuthContext);

  const { encryptData, decryptData } = useCrypto();
  const categoryId = state?.category?.id;

  const [tenants, setTenants] = useState([]);
  const [tenantsSelect, setTenantsSelect] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tenant, setTenant] = useState({});
  const [editTenant, setEditTenant] = useState({
    categoryId: 0,
    tenantId: '',
    tenantName: '',
    allUsers: false,
    userGroups: [],
    nextEmailDelay: null,
    fastTrackNextEmailDelay: null,
    resendingEmailCount: null,
    scheduledLaunch: null,
    categoryScheduledLaunch: null,
    launched: false,
    annuallyRepeat: false,
    fastTrackCompliance: false,
    fastTrackComplianceDisabled: false,
    fastrackSendingDuration: maxFastrackSendingDuration,
  });
  const [editCategoryModal, openEditCategoryModal] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [advancedFastTrackSettings, setAdvancedFastTrackSettings] = useState(false);

  useEffect(() => {
    setSelectedTenant(null);
  }, [categoryId])

  const closeEditCategoryModal = () => {
    openEditCategoryModal(false);
    setTenant({});
    setEditTenant({
      categoryId: 0,
      tenantId: '',
      tenantName: '',
      allUsers: false,
      userGroups: [],
      nextEmailDelay: null,
      fastTrackNextEmailDelay: null,
      resendingEmailCount: null,
      scheduledLaunch: null,
      categoryScheduledLaunch: null,
      launched: false,
      fastTrackCompliance: false,
      fastrackSendingDuration: maxFastrackSendingDuration,
    });
    setAdvancedFastTrackSettings(false);
  }

  const { items, requestSort, sortConfig } = useSortableData(tenants.sort((a, b) => b.configured - a.configured));
  const fetchCategoryPerTenants = useCallback(async (categoryId) => {
    try {
      if (token) {

        const fetched = await request(`/back_office/api/categories/category_per_tenant/${categoryId}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptCategory = decryptData(fetched);
        setTenants(decryptCategory);

        return decryptCategory;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (!!categoryId) fetchCategoryPerTenants(categoryId);
  }, [fetchCategoryPerTenants, categoryId]);

  const updateCategoryPerTenant = async (editTenant) => {
    try {
      closeEditCategoryModal();
      const data = encryptData(editTenant);
      const res = await request('/back_office/api/categories/category_per_tenant', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })

      showToastMessage(res.error, res.message);

      fetchCategoryPerTenants(categoryId);
    } catch (error) { }
  };

  const groupsOptionList = state?.groups?.map((group) => ({ value: group.id, label: group?.label }));

  useEffect(() => {
    const usersTenants = tenants.map(el => ({
      tenantName: el.name,
      tenantId: el.id,
      syncType: el.type
    }));
    const uniqueObjects = [...new Set(usersTenants.map(JSON.stringify))].map(JSON.parse).sort((a, b) => a.tenantName.localeCompare(b.tenantName));
    const selectTenantArray = [...uniqueObjects]
    const tenantList = selectTenantArray.map((v, i) => ({
      value: v?.tenantName?.toLowerCase(),
      label: v?.tenantName,
      tenantId: v?.tenantId,
      id: i,
      tenant: true,
      syncType: v?.syncType
    }))

    setTenantsSelect(tenantList)
  }, [tenants, tenants.length]);

  const Option = (props) => {
    const { value, label, caption, tenant, allUsers, syncType } = props.data;

    return (
      <components.Option {...props}>
        <div className={cn(`syncTypes-label-${value.toString().split(' ').join('-')} syncTypes-label-${syncType} userslist-select2-${tenant}`, {
            'all-users-label': allUsers,
            'users-list-tenant-label': tenant
          })}
        >
          {label} {syncType ? `(${syncType})` : ''}
        </div>
        <div className='caption'>{caption}</div>
      </components.Option>
    );
  };

  const handleGroupsChange = (data) => {
    setGroups(data);
    setEditTenant(prev => ({
      ...prev,
      userGroups: data.map(g => g.value)
    }))
  };

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
  };

  useEffect(() => {
    if (tenant.id) {
      setGroups(tenant.userGroups.map(group => (
        { value: group, label: state?.groups?.find(g => g.id === group)?.label }
      )))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  const tomorrow = moment().utc().add(1, 'day').format('YYYY-MM-DD');
  const nextMonth = moment().utc().add(1, 'month').format('YYYY-MM-DD');
  const minDate = editTenant.scheduledLaunch && moment(editTenant.scheduledLaunch).utc().format('YYYY-MM-DD') > tomorrow
    ? editTenant.scheduledLaunch
    : tomorrow;
  const maxDate = editTenant.scheduledLaunch && moment(editTenant.scheduledLaunch).utc().add(1, 'month').format('YYYY-MM-DD') > nextMonth
    ? moment(editTenant.scheduledLaunch).utc().add(1, 'month').format('YYYY-MM-DD')
    : nextMonth;

  return (
    <>
      <div className="category-main">
        <div className="category-main-description">
          <h5 className="category-main-name">{state?.category?.name}</h5>
          <p>{state?.category?.description}</p>
        </div>
        {!loading
          ? <>
              <div>
                <div className='mandatory-table-tenant'>
                  <div className={`input-field-mandatory-tenant input-field-tenant-${selectedTenant?.syncType}`}>
                    <Select
                      id='select'
                      options={tenantsSelect}
                      components={{ Option }}
                      placeholder="Search Tenant..."
                      className="select-edit-mandatory"
                      value={selectedTenant}
                      onChange={handleTenantChange}
                      isSearchable={true}
                      styles={colourStyles}
                      isClearable
                    />
                  </div>
                </div>
                <table className="category-table">
                  <thead className="table-th-category">
                    <tr className="not-clickable-category tr-grid-category tr-category">
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('tenantName')}
                          className={getClassNamesFor('tenantName', sortConfig)}
                        >
                          Tenant
                        </button>
                      </th>
                      <th>
                        Active For
                      </th>
                      <th>
                        Launch date
                      </th>
                      <th>
                        Next email delay
                      </th>
                      <th>
                        Resending count
                      </th>
                      <th>
                        Annual recurrence
                      </th>
                      <th>
                        Expedited training
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('configured')}
                          className={getClassNamesFor('configured', sortConfig)}
                        >
                          Status
                        </button>
                      </th>
                      <th>
                        Edit
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body table-body-category">
                    {items
                      .filter(t => selectedTenant
                        ? (t.name === selectedTenant?.label && t.type === selectedTenant?.syncType)
                        : t
                      )
                      .map((t) => (
                        <tr
                          key={t.id}
                          className={"tr-grid-category clickable-row-category tr-category"}
                          >
                            <td className={`list-td-category list-td-category-tenant-${t.type}`}>{t.name} ({t.type})</td>
                            <td className='list-td-category list-td-category-groups'>
                              {t.configured && (t.allUsers
                                ? <span className='td-category-all-users'>All users</span>
                                : t.userGroups?.length
                                  ? <>
                                      <span className='td-category-groups'>Groups:</span>
                                      <div className='td-category-groups-container'>
                                        {t.userGroups
                                          .map(id => (
                                            <span key={id} className='td-category-groups-name'>
                                              {state?.groups?.find(g => g.id === id).label}
                                            </span>
                                          ))
                                        }
                                      </div>
                                    </>
                                  : null)
                              }
                            </td>

                            <td className='list-td-category'>
                              {t.configured &&
                                <span className='td-category-all-users'>{
                                  t.categoryScheduledLaunch
                                    ? moment(t.categoryScheduledLaunch).toDate() <= moment().toDate()
                                      ? <>{moment(t.categoryScheduledLaunch).format('MM/DD/YYYY')} <span className='launched'>Launched</span></>
                                      : <>{moment(t.categoryScheduledLaunch).format('MM/DD/YYYY')} <span className='scheduled'>Scheduled</span></>
                                    : null
                              }</span>}
                            </td>
                            <td className='list-td-category'>
                              {t.configured &&
                                <span className='td-category-all-users'>{t.nextEmailDelay}</span>
                              }
                            </td>
                            <td className='list-td-category'>
                              {t.configured && (!t.fastTrackCompliance
                                ? <span className='td-category-all-users'>{t.resendingEmailCount}</span>
                                : <span className='td-category-all-users'>-</span>)
                              }
                            </td>
                            <td className='list-td-category'>
                              {t.configured && (
                                t.annuallyRepeat
                                  ? <span className='list-td-status-fast-tack-compliance'>Yes</span>
                                  : <span className='list-td-status-not-defined'>No</span>
                              )}
                            </td>
                            <td className='list-td-category'>
                              {t.configured && (
                                t.fastTrackCompliance
                                  ? <span className='list-td-status-fast-tack-compliance'>Yes</span>
                                  : <span className='list-td-status-not-defined'>No</span>
                              )}
                            </td>
                            <td className='list-td-category'>
                              {t.configured
                                ? <div className='list-td-status-configured'>Configured</div>
                                : <div className='list-td-status-not-defined'>Not defined</div>
                              }
                            </td>
                            <td className='list-td-category'>
                              <i
                                role='button'
                                onClick={() => {
                                  setTenant(t)
                                  setEditTenant({
                                    categoryId,
                                    tenantId: t.id,
                                    tenantName: t.name,
                                    allUsers: t.allUsers,
                                    userGroups: t.userGroups,
                                    nextEmailDelay: t.nextEmailDelay || state?.category?.nextEmailDelay,
                                    fastTrackNextEmailDelay: t.nextEmailDelay || minNextEmailDelay,
                                    resendingEmailCount: t.resendingEmailCount || state?.category?.resendingEmailCount,
                                    scheduledLaunch: t.scheduledLaunch ? moment(t.scheduledLaunch).format('YYYY-MM-DD') : null,
                                    categoryScheduledLaunch: t.categoryScheduledLaunch ? moment(t.categoryScheduledLaunch).format('YYYY-MM-DD') : null,
                                    launched: moment(t.categoryScheduledLaunch).format('MM/DD/YYYY') <= moment().format('MM/DD/YYYY'),
                                    annuallyRepeat: t.annuallyRepeat,
                                    fastTrackCompliance: t.fastTrackCompliance,
                                    fastTrackComplianceDisabled: t.fastTrackCompliance,
                                    fastrackSendingDuration: t.fastrackSendingDuration || maxFastrackSendingDuration
                                  })
                                  openEditCategoryModal(true)
                                }}
                              />
                            </td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          : <Loader />
        }
      </div>

      <MandatoryTrainingUsers
        categoryId={categoryId}
        selectedTenant={selectedTenant}
      />

      <Modal
        isOpen={editCategoryModal}
        onRequestClose={closeEditCategoryModal}
        style={customModalStyles}
        contentLabel="Category Modal"
      >
        <form className="category-form">
          <div className="category-content">
            <span className="card-title">Edit Category</span>
            <span className="card-subtitle">Tenant: <strong>{tenant.name} ({tenant.type})</strong></span>
            <div className="card-content">
              <div className="card-content-all-users">
                <label
                  className={"slider-checkbox"}
                >
                  <input
                    type="checkbox"
                    className='checkbox-input'
                    checked={editTenant.allUsers}
                    onChange={() => setEditTenant(prev => ({
                      ...prev,
                      allUsers: !editTenant.allUsers
                    }))}
                  />
                  <span className={"slider"} />
                </label>
                <label className='category-select-label--1'>All users</label>
              </div>

              {user.paymentPlanId === 4 &&
                <div className={cn({ 'category-input-disabled': editTenant.allUsers })}>
                  <div className='category-input'>
                    <label className='category-select-label'>
                      Choose groups
                      <Select
                        id='select'
                        className='group-multiselect'
                        options={groupsOptionList}
                        components={{ Option }}
                        placeholder="Select group(s)..."
                        value={!editTenant.allUsers ? groups : null}
                        onChange={handleGroupsChange}
                        isSearchable={true}
                        styles={colourStyles}
                        isMulti
                        closeMenuOnSelect={false}
                        isDisabled={editTenant.allUsers}
                      />
                    </label>
                  </div>
                </div>
              }

              {!editTenant.launched &&
                <div className={cn('category-input-container', {
                  'category-input-disabled': !editTenant.allUsers && !editTenant.userGroups.length
                  })}
                >
                  <label className='category-input-label'>
                    Schedule the Launch<span>*</span>:
                  </label>
                  <input
                    type="date"
                    value={editTenant.categoryScheduledLaunch}
                    min={minDate}
                    max={maxDate}
                    className="input-edit-category"
                    onChange={(e) => {
                      setEditTenant(prev => ({
                      ...prev,
                        categoryScheduledLaunch: e.target.value
                    }))}}
                  />
                </div>
              }

              <div className={cn('card-content-fastrack-compliance', {
                'category-input-disabled': !editTenant.allUsers && !editTenant.userGroups.length
              })}>
                <label
                  className={"slider-checkbox"}
                >
                  <input
                    type="checkbox"
                    className='checkbox-input'
                    checked={editTenant.annuallyRepeat}
                    onChange={() => setEditTenant(prev => ({
                      ...prev,
                      annuallyRepeat: !editTenant.annuallyRepeat
                    }))}
                  />
                  <span className={"slider"} />
                </label>
                <label className='category-select-label--1'>
                  Repeat Training Annually?
                </label>
                <CustomizedTooltipInfo
                  text={
                    `If this configuration is enabled, the training will be repeated every year
                    and all previous progress for each user will be updated.`
                  }
                />
              </div>

              <div className={cn('card-content-fastrack-compliance', {
                'category-input-disabled': (!editTenant.allUsers && !editTenant.userGroups.length)
                  || editTenant.fastTrackComplianceDisabled
              })}>
                <label
                  className={"slider-checkbox"}
                >
                  <input
                    type="checkbox"
                    className='checkbox-input'
                    checked={editTenant.fastTrackCompliance}
                    onChange={() => setEditTenant(prev => ({
                      ...prev,
                      fastTrackCompliance: !editTenant.fastTrackCompliance
                    }))}
                    disabled={editTenant.fastTrackComplianceDisabled}
                  />
                  <span className={"slider"} />
                </label>
                <label className='category-select-label--1'>
                  Fasttrack training?
                </label>
                <CustomizedTooltipInfo
                  text={
                    `If you activate this feature, all new users who match the selected
                    criteria for mandatory training will start receiving an email with a link
                    to the landing page with the missed videos.`
                  }
                />
              </div>

              {editTenant.fastTrackCompliance && (!!editTenant.allUsers || !!editTenant.userGroups.length) &&
                <div className={cn({
                  'category-input-disabled': !editTenant.allUsers && !editTenant.userGroups.length
                })}>
                  <label className='advaced-fasttrack-settings'>
                    <input
                      type="checkbox"
                      className="advaced-fasttrack-settings-checkbox"
                      checked={advancedFastTrackSettings}
                      onChange={() => setAdvancedFastTrackSettings(prevState => !prevState)}
                    />
                    Add advanced fastrack settings
                  </label>
                </div>
              }

              {advancedFastTrackSettings &&
                <>
                  <div className={cn('category-input-container', {
                    'category-input-disabled': !editTenant.allUsers && !editTenant.userGroups.length
                    })}
                  >
                    <label className='category-input-label'>
                      Fasttrack sending duration (in days):
                    </label>
                    <input
                      type="number"
                      min={minFastrackSendingDuration}
                      max={maxFastrackSendingDuration}
                      step={1}
                      value={editTenant.fastrackSendingDuration || state?.category?.fastrackSendingDuration}
                      className="input-edit-category"
                      onChange={(e) => {
                        setEditTenant(prev => ({
                          ...prev,
                          fastrackSendingDuration: Math.round(+e.target.value)
                        }))

                        if (+e.target.value < minFastrackSendingDuration) {
                          setEditTenant(prev => ({
                            ...prev,
                            fastrackSendingDuration: minFastrackSendingDuration
                          }))
                        }
                        if (+e.target.value > maxFastrackSendingDuration) {
                          setEditTenant(prev => ({
                            ...prev,
                            fastrackSendingDuration: maxFastrackSendingDuration
                          }))
                        }
                      }}
                      disabled={!advancedFastTrackSettings}
                    />
                  </div>

                  <div className={cn('category-input-container', {
                  'category-input-disabled': !editTenant.allUsers && !editTenant.userGroups.length
                  })}
                >
                  <label className='category-input-label'>
                    Set up Next email delay (in days):
                  </label>
                  <input
                    type="number"
                    min={minNextEmailDelay}
                    max={maxNextEmailDelay}
                    step={1}
                    value={editTenant.fastTrackNextEmailDelay}
                    className="input-edit-category"
                    onChange={(e) => {
                      setEditTenant(prev => ({
                        ...prev,
                        fastTrackNextEmailDelay: Math.round(+e.target.value)
                      }))

                      if (+e.target.value < minNextEmailDelay) {
                        setEditTenant(prev => ({
                          ...prev,
                          fastTrackNextEmailDelay: minNextEmailDelay
                        }))
                      }
                      if (+e.target.value > maxNextEmailDelay) {
                        setEditTenant(prev => ({
                          ...prev,
                          fastTrackNextEmailDelay: maxNextEmailDelay
                        }))
                      }
                    }}
                  />
                </div>
                </>
              }

              {!editTenant.fastTrackCompliance &&
              <>
                <div className={cn('category-input-container', {
                  'category-input-disabled': !editTenant.allUsers && !editTenant.userGroups.length
                  })}
                >
                  <label className='category-input-label'>
                    Set up Next email delay (in days):
                  </label>
                  <input
                    type="number"
                    min={minNextEmailDelay}
                    max={maxNextEmailDelay}
                    step={1}
                    value={editTenant.nextEmailDelay || state?.category?.nextEmailDelay}
                    className="input-edit-category"
                    onChange={(e) => {
                      setEditTenant(prev => ({
                        ...prev,
                        nextEmailDelay: Math.round(+e.target.value)
                      }))

                      if (+e.target.value < minNextEmailDelay) {
                        setEditTenant(prev => ({
                          ...prev,
                          nextEmailDelay: minNextEmailDelay
                        }))
                      }
                      if (+e.target.value > maxNextEmailDelay) {
                        setEditTenant(prev => ({
                          ...prev,
                          nextEmailDelay: maxNextEmailDelay
                        }))
                      }
                    }}
                  />
                </div>
                <div className={cn('category-input-container', {
                  'category-input-disabled': !editTenant.allUsers && !editTenant.userGroups.length
                  })}
                >
                  <label className='category-input-label'>
                    Configure the number of resends same email:
                  </label>
                  <input
                    type="number"
                    min={minResendingEmailCount}
                    max={maxResendingEmailCount}
                    step={1}
                    value={editTenant.resendingEmailCount >= 0 ? editTenant.resendingEmailCount : state?.category?.resendingEmailCount}
                    className="input-edit-category"
                    onChange={(e) => {
                      setEditTenant(prev => ({
                        ...prev,
                        resendingEmailCount: Math.round(+e.target.value)
                      }))

                      if (+e.target.value <= minResendingEmailCount) {
                        setEditTenant(prev => ({
                          ...prev,
                          resendingEmailCount: minResendingEmailCount
                        }))
                      }
                      if (+e.target.value > maxResendingEmailCount) {
                        setEditTenant(prev => ({
                          ...prev,
                          resendingEmailCount: maxResendingEmailCount
                        }))
                      }
                    }}
                  />
                </div>
                </>
              }
            </div>
          </div>

          <div className="category-card-action-modal">
            <button
              type='button'
              onClick={() => updateCategoryPerTenant(editTenant)}
              disabled={
                ((editTenant.allUsers || editTenant.userGroups.length) && !editTenant.categoryScheduledLaunch)
                || (!editTenant.launched && !validateDateScheduling(editTenant.categoryScheduledLaunch, minDate, maxDate))
                || loading
              }
            >
              Save changes
            </button>
            <button
              type='button'
              onClick={closeEditCategoryModal}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
};
