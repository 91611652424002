/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import colourStyles from '../../styles/colour-style';
import Select, { components } from 'react-select';
import { useCrypto } from '../../hooks/crypto.hook';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { syncOptions } from '../../config/config';
import AsyncSelect from 'react-select/async';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import './CustomModal.css';

export const SyncModal = (props) => {
  const { group, setSyncModal, syncModal } = props;
  const { loading, request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const [optionList, setOptionList] = useState([]);
  const [syncGroups, setSyncGroupsOptionList] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({
    label: group.split('-').join(' '),
    value: group,
  });
  // const [syncModal, setSyncModal] = useState(true);
  const [selectedSyncType, setSyncType] = useState(syncOptions[0]);
  const [selectedSyncGroups, setSyncGroups] = useState();
  const [selectedTenant, setSelectedTenant] = useState({});
  const navigate = useNavigate();
  const { showToastMessage, allUsers, groupsLogs } = useContext(AuthContext);
  const [exclusions, setExclusions] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [initialGroups, setInitialGroups] = useState([]);
  const [syncTypes, setSyncTypes] = useState(syncOptions);
  const [step, setStep] = useState(1);
  const [azureGroups, setAzureGroups] = useState({});

  const normalizedGroupName = group?.split('-').join(' ');

  const getGroupMembers = useCallback(async () => {
    try {
      if (selectedSyncType.value === 'azure') {
        for (const group of selectedSyncGroups) {
          const groupId = group.value;
          if (!azureGroups[groupId]) {
            const fetched = await requestWithSecondaryLoading(
              `/back_office/api/groups/get_groups_members_azure?groupId=${groupId}&tenantId=${selectedTenant?.id}`,
              'GET',
              null,
              {
                Authorization: `Bearer ${token}`,
              }
            );
            const data = decryptData(fetched)

            setAzureGroups((prev) => { return { ...prev, [groupId]: data } });
          }
        }
      }

    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, selectedTenant?.id, selectedSyncGroups]);

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const syncOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')} users-list-tenant-label`}>
          {label}
        </div>
      </components.Option>
    );
  };

  const closeModal = () => {
    setAzureGroups({});
    setStep(1)
    setSyncModal(false);
  }

  const syncGroupsVariations = () => {
    return tenantGroups?.map((e) => {
      return { value: e.id, label: e.name, id: e.id };
    });
  };

  const handleSyncGroupsChange = (data) => {
    setSyncGroups(data);
    const exclusionsEmails = exclusions.map(e => e.value)
    const groupIds = data?.map((e) => e.value);
    setSelectedMembers(getSelectedUsers(groupIds).filter((e) => !exclusionsEmails.includes(e.email)));
  };

  const changeGroupMapping = async () => {
    const selectedData = {
      selectedUsersGroups: selectedSyncGroups,
      destGroup: selectedOptions.label,
      tenantName: selectedTenant.label,
      syncType: selectedSyncType.value,
      exclusionsEmails: exclusions.map((e) => e.value),
    };
    if (token) {
      const data = encryptData(selectedData);
      const response = await request(
        `/back_office/api/groups/create/groups_map`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      showToastMessage(response.error, response.message);
      if (!response.error) {
        return navigate(`/back_office/groups/${group}`);
      }
    }
  };

  const getGroups = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(getGroupsFunction(inputValue));
      }, 2000);
    });

  const getGroupsFunction = async (inputValue) => {
    try {
      if (token && selectedTenant.id && selectedSyncType.value === 'azure') {
        const fetched = await requestWithSecondaryLoading(
          `/back_office/api/groups/sync_group_list_azure?groupQuery=${inputValue}&tenantId=${selectedTenant?.id}&syncType=${selectedSyncType?.value}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const data = decryptData(fetched)

        return data.map(e => {
          return {
            value: e.id,
            label: e.displayName,
          }
        });
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getExclusions = useCallback(
    async () => {
      try {
        if (token) {
          const fetched = await requestWithSecondaryLoading(
            `/back_office/api/groups/${selectedOptions.label
              .split(' ')
              .join('-')}/exclusions`,
            'GET',
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );
          const decryptExclusions = decryptData(fetched);
          const filteredExclusions = decryptExclusions.filter((e) => e.tenantId === selectedTenant.id && e.syncType === selectedSyncType.value)
          const exclusionOptions = getExclusionUsers(filteredExclusions);

          setExclusions(exclusionOptions);

          return decryptExclusions;
        }
      } catch (error) { }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, request, selectedTenant]
  );

  const getAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/user/get_all_tenants',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptUsers = decryptData(fetched);
        setTenants(decryptUsers.filter((t) => t.type !== 'csv'));
        const syncTypesOptions = decryptUsers.map((t) => t.type);
        const syncOptions = syncTypes.filter((t) =>
          syncTypesOptions.includes(t.value)
        );
        setSyncTypes(syncOptions);

        const firstTenant = decryptUsers?.filter(
          (t) =>
            t.groupsMap.find((g) => g.systemGroup === normalizedGroupName)
              ?.sourceGroups.length
        )[0];

        setSyncType(syncOptions.find((t) => t.value === firstTenant?.type));

        setSelectedTenant({
          value: firstTenant?.name.split(' ').join('-'),
          label: firstTenant?.name,
          id: firstTenant?.id,
        });

        const selectedGroups = firstTenant?.groupsMap.find(
          (g) => g.systemGroup === normalizedGroupName
        )?.sourceGroups;
        const groups = getGroupsForTenant(
          firstTenant?.name,
          firstTenant?.type
        ).filter((g) => selectedGroups.includes(g.id));

        setSyncGroups(
          groups.map((e) => {
            return { value: e.id, label: e.name, id: e.id };
          })
        );

        return tenants;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, groupsLogs]);

  const getGroupsForTenant = (tenantName, syncType) => {
    const groups = groupsLogs[syncType]?.find(
      (l) => l.tenantName === tenantName
    )?.userGroups;

    return groups;
  };

  const azureTenants = tenants?.filter((t) => t.type === 'azure');
  const googleTenants = tenants?.filter((t) => t.type === 'google');
  const tenantGroups = getGroupsForTenant(
    selectedTenant?.label,
    selectedSyncType?.value
  );

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    const tenant = tenants?.find((t) => t.name === data.label);
    const selectedGroups = tenant?.groupsMap.find(
      (g) => g.systemGroup === normalizedGroupName
    )?.sourceGroups;
    const groups = getGroupsForTenant(tenant?.name, tenant?.type).filter(
      (g) => selectedGroups?.includes(g.id)
    );

    setSyncGroups(
      groups.map((e) => {
        return { value: e.id, label: e.name, id: e.id };
      })
    );
    setSelectedMembers([]);
  };

  const azureTenantsArray = azureTenants?.map((e, i) => {
    return { value: e.name, label: e.name, id: e.id };
  });
  const googleTenantsArray = googleTenants?.map((e, i) => {
    return { value: e.name, label: e.name, id: e.id };
  });

  const getSelectedUsers = (groupsIds) => {
    const selectedGroups = tenantGroups?.filter((g) =>
      groupsIds?.includes(g.id)
    );
    const usersEmails = selectedGroups
      ?.map((g) => g.members)
      .flat()
      .map((m) => m.mail);

    const groupsUsers = allUsers?.filter((u) => usersEmails?.includes(u.email));
    return groupsUsers;
  };

  const getUsersOptions = (groupsIds = []) => {
    if (selectedSyncType.value === 'azure') {
      const userEmails = selectedSyncGroups?.map((g) => azureGroups[g.value]).flat();
      const uniqueEmails = [...new Set(userEmails)];
      const uniqueUsers = allUsers?.filter((u) => uniqueEmails?.includes(u.email));
      const options = uniqueUsers?.map((u) => {
        return {
          value: u.email,
          label: `${u.firstName} ${u.secondName} <${u.email}>`,
          key: u.email,
        };
      });

      return options;
    }
    const groupsUsers = getSelectedUsers(groupsIds);
    let options;
    if (!groupsUsers.length) {
      options = allUsers?.map((u) => {
        return {
          value: u.email,
          label: `${u.firstName} ${u.secondName} <${u.email}>`,
          key: u.email,
        };
      });
    } else {
      options = groupsUsers?.map((u) => {
        return {
          value: u.email,
          label: `${u.firstName} ${u.secondName} <${u.email}>`,
          key: u.email,
        };
      });
    }

    return options;
  };

  const tenantsOption = (props) => {
    const { label } = props.data;

    return (
      <components.Option {...props}>
        <div className={`users-list-tenant-label`}>{label}</div>
      </components.Option>
    );
  };

  const getExclusionUsers = (exclusions) => {
    const excludedEmails = exclusions?.map((e) => e.userEmail);
    const excludedUsers = allUsers.filter((u) =>
      excludedEmails?.includes(u.email)
    );

    const options = excludedUsers.map((u) => {
      return {
        value: u.email,
        label: `${u.firstName} ${u.secondName} <${u.email}>`,
        key: u.id,
      };
    });

    return options;
  };

  const addExclusions = async (groupName, users) => {
    try {
      const payload = {
        selectedUsers: users.map((u) => u.value),
        tenant: { name: selectedTenant.label, id: selectedTenant.id, type: selectedSyncType.value },
      };

      if (token) {
        const fetched = await request(
          `/back_office/api/groups/${groupName
            .split(' ')
            .join('-')}/exclusions`,
          'POST',
          payload,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptExclusions = decryptData(fetched);
        const tenantExclusions = decryptExclusions.filter(
          (e) => e.tenantName === selectedTenant.label
        );
        const exclusionOptions = getExclusionUsers(tenantExclusions);
        setExclusions(exclusionOptions);
        if (!fetched.error) {
          navigate(
            `/back_office/groups/${selectedOptions?.label?.split(' ').join('-')}`
          );
        }
      }
    } catch (error) { }
  };

  const syncGroupsOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')} users-list-tenant-label`}>
          {label}
        </div>
      </components.Option>
    );
  };

  const handleSyncTypeChange = (data) => {
    setSyncType(data);
    handleTenantChange(
      data.value === 'azure' ? azureTenantsArray[0] : googleTenantsArray[0]
    );
  };

  const handleEditExclusionsChange = (data) => {
    setExclusions(data);
    const exlusionEmails = data.map((e) => e.value);
    const users = getSelectedUsers(selectedSyncGroups?.map((e) => e.value));
    setSelectedMembers(users.filter((e) => !exlusionEmails.includes(e.email)))
  };

  const customStyles = {
    content: {
      padding: '0',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 2px 8px rgba(52, 50, 50, 0.15)',
      borderRadius: '4px',
      overflow: 'visible',
    },
  };

  const fetchGroupList = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/groups/group_list',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptGroups = decryptData(fetched);
        setOptionList(decryptGroups);

        return decryptGroups;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, group]);

  const getInitialGroups = useCallback(
    async () => {
      try {
        if (token && selectedTenant.id && selectedSyncType.value === 'azure') {
          const fetched = await requestWithSecondaryLoading(
            `/back_office/api/groups/sync_group_list_azure?groupQuery=${inputValue}&tenantId=${selectedTenant?.id}&syncType=${selectedSyncType.value}&limit=999`,
            'GET',
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );
          const data = decryptData(fetched)

          const groups = data.map(e => {
            return {
              value: e.id,
              label: e.displayName,
            }
          });
          setInitialGroups(groups);
        }
      } catch (error) { }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [token, request, selectedTenant?.id]);

  useEffect(() => {
    getInitialGroups();
  }, [getInitialGroups]);

  useEffect(() => {
    fetchGroupList();
  }, [fetchGroupList]);

  useEffect(() => {
    getGroupMembers();
  }, [getGroupMembers]);

  useEffect(() => {
    getAllTenants();
  }, [getAllTenants]);

  useEffect(() => {
    getExclusions();
  }, [getExclusions]);

  if (loading) {
    return <Loader />;
  }

  const Option = (props) => {
    const { label, caption } = props.data;

    return (
      <components.Option {...props}>
        <div className="new-group-users-table-label">{label}</div>
        <div className="group-caption">{caption}</div>
      </components.Option>
    );
  };
  return (
    <Modal
      isOpen={syncModal}
      onRequestClose={() => closeModal()}
      style={customStyles}
      contentLabel="Sync groups modal"
    >
      <form className="form modal-form">
        <div className="title modal-title">
          Sync with integration groups
          <br></br>
        </div>
        <div className="card-content modal-content">
          {step === 1 && <>
            <div className="new-group-select">
              <label className="userslist-select-label">
                Sync method
                <Select
                  id="select"
                  options={syncTypes}
                  components={{ syncOption }}
                  placeholder="Select sync type"
                  value={selectedSyncType}
                  onChange={handleSyncTypeChange}
                  isSearchable={true}
                  styles={colourStyles}
                />
              </label>
            </div>
            <div className="new-group-select">
              <label className="userslist-select-label">
                Tenant
                <Select
                  id="select"
                  options={
                    selectedSyncType?.value === 'azure'
                      ? azureTenantsArray
                      : googleTenantsArray
                  }
                  components={{ tenantsOption }}
                  placeholder="Select sync type"
                  value={selectedTenant}
                  onChange={handleTenantChange}
                  isSearchable={true}
                  styles={colourStyles}
                />
              </label>
            </div>
            <strong>FROM</strong>
            <div className="new-group-select">
              <label className="userslist-select-label">
                Choose group
                {selectedSyncType.label ? ' in ' + selectedSyncType.label : ''}
                {selectedSyncType.value === 'azure' ? (
                  <AsyncSelect
                    id="select"
                    placeholder="Type to find group"
                    value={selectedSyncGroups}
                    onChange={handleSyncGroupsChange}
                    isSearchable={true}
                    inputValue={inputValue}
                    onInputChange={(e) => setInputValue(e)}
                    styles={colourStyles}
                    loadOptions={getGroups}
                    isLoading={secondaryLoading}
                    isMulti
                    closeMenuOnSelect={true}
                    optionComponent={syncGroupsOption}
                    defaultOptions={initialGroups}
                    cacheOptions={true}
                  />
                ) : (
                  <ReactSelect
                    id="select"
                    options={syncGroupsVariations()}
                    optionComponent={syncGroupsOption}
                    placeholder="Select group"
                    value={selectedSyncGroups}
                    onChange={handleSyncGroupsChange}
                    isSearchable={true}
                    styles={colourStyles}
                    isMulti
                  />)}
              </label>
            </div>
            <strong>TO</strong>
            <div className="new-group-select">
              <label className="userslist-select-label">
                <Select
                  id="select"
                  options={optionList}
                  components={{ Option }}
                  placeholder="Select group"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  styles={colourStyles}
                  isDisabled={!!group}
                />
              </label>
            </div>
          </>}
          {step === 2 && <>

            <div className="new-group-select">
              <label className="userslist-select-label">
                Excluded users
                <Select
                  id="select"
                  options={getUsersOptions(
                    selectedSyncGroups?.map((e) => e.value)
                  )}
                  components={{ syncGroupsOption }}
                  placeholder="Select users to exclude"
                  value={exclusions}
                  onChange={handleEditExclusionsChange}
                  isSearchable={true}
                  styles={colourStyles}
                  isMulti
                />
              </label>
            </div>
            {/* <div className="modal-users-list">
          <>
            {!!selectedMembers.length && (
              <span className="card-title">Synced users</span>
            )}
            <br></br>
            <div>
              <div className="modal-users-list-scroll">
                {selectedMembers?.map((user, i) => {
                  return (
                    <div key={user.id}>
                      {i + 1}. {user?.firstName} {user?.secondName}
                      <strong>
                        {'<'}
                        {user.email}
                        {'>'}
                      </strong>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        </div> */}
          </>}
        </div>


        {step === 1 && <div className="modal-button-container">
          <button
            className="group-button-secondary"
            onClick={() => closeModal()}
          >
            Cancel
          </button>

          <button
            className="group-button group-button-simple"
            disabled={!selectedSyncGroups?.length}
            onClick={() => setStep(2)}
          >
            Next
          </button>
        </div>}
        {step === 2 && <div className="modal-button-container">
          <button
            className="group-button-secondary"
            onClick={() => setStep(1)}
          >
            Back to group selection
          </button>

          <button
            className="group-button group-button-simple"
            disabled={!selectedSyncGroups?.length}
            onClick={async () => {
              await changeGroupMapping();
              await addExclusions(selectedOptions.label, exclusions);
              closeModal();
            }}
          >
            Submit
          </button>
        </div>}
      </form>
    </Modal>
  );
};
