import './DetailsPage.css';
import edit from '../.././images/icons/Pen.svg';
import { SyncLog } from '../../components/SyncLog/SyncLog';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../hooks/auth.hook';
import { SyncGroupsAzure } from '../../components/SyncGroups/SyncGroupsAzure.js';
import { CustomizedTooltipDisabled } from '../../components/Tooltips/Tooltip.js';

export const ADDetails = () => {
  const { token } = useAuth();
  const { loading, request } = useHttp();
  const [tenants, setTenants] = useState([]);
  const { user } = useContext(AuthContext);

  const getTenants = useCallback(async () => {
    try {
      if (token) {
        const tenants = await request('/back_office/api/user/tenants', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        setTenants(tenants);

        return tenants;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    getTenants();
  }, [getTenants]);
  if (loading) {
    return <Loader />
  }

  return (
    <>
      {!loading && (
        <>
          <div className="main-details">
            <div className="details-card">
              <div className="details-action-ad">
                <div>
                  <h3 className="details-action-title">
                    Azure Active Directory details
                  </h3>
                </div>
              </div>

              {tenants?.map((tenant) => (
                <ul className="details-account-list" key={tenant.id}>
                  <li className="details-item">
                    Tenant name
                    <p className="details-value">{tenant.name}</p>
                  </li>
                  <li className="details-item">
                    Inclusion Group(s)
                    <p className="details-value">
                      {tenant.inclusionGroup?.activeGroups?.join(', ') || '-'}
                    </p>
                  </li>
                  <li className="details-item">
                    Exclusion Group(s)
                    <p className="details-value">
                      {tenant.exclusionGroup?.inactiveGroups?.join(', ') || '-'}
                    </p>
                  </li>
                  <li className="details-item">
                    Filter users:
                    <div className="details-value">
                      {tenant.filterUsers ? (
                        <span>&#10004;</span>
                      ) : (
                        <span>&#10006;</span>
                      )}
                    </div>
                  </li>
                  {tenant.language && <li className="details-item">
                    Language
                    <p className="details-value">{tenant.language}</p>
                  </li>}
                  <li className="details-item details-item-button">
                    <NavLink
                      to={`/back_office/user/users_database/ad/edit/${tenant.id}`}
                      className="button-link"
                    >
                      <button>
                        <img src={edit} alt="edit"></img>
                        Edit
                      </button>
                    </NavLink>
                  </li>
                </ul>
              ))}

              {(!user?.syncAD || (!user?.tenants?.syncAccess && user?.role !== 'owner')) ?
                <CustomizedTooltipDisabled
                  position={'left'}
                  text1={'The Owner did not'}
                  text2={'give you permission'}
                  text3={'to set up a synchronization'}
                  button={<NavLink
                    to="/back_office/user/users_database/ad/create"
                    className="edit-submit"
                  >
                    <button className="button-submit" disabled={true}>+ Add one more tenant</button>
                  </NavLink>}
                />
                : <NavLink
                  to="/back_office/user/users_database/ad/create"
                  className="edit-submit"
                >
                  <button className="button-submit">+ Add one more tenant</button>
                </NavLink>
              }
            </div>
            <SyncGroupsAzure syncType={'azure'} />
            {!loading ? (
              <SyncLog activeDirectory={true} csv={false} syncType={'azure'} />
            ) : (
              <Loader />
            )}
          </div>
        </>
      )}
    </>
  );
}
