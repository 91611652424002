import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { Loader } from '../../components/Loader/Loader';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';
import { AuthContext } from '../../context/AuthContext';
import { useTitle } from '../../hooks/title.hook';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import { PhishReportsOption } from '../../components/ReactSelect/ReactSelectOption';
import './ReportedPhish.css';

export const ReportedPhish = () => {
  useTitle("PhishFirewall | Reported phish")

  const { loading } = useHttp();
  const { phishReports } = useContext(AuthContext);
  const navigate = useNavigate();

  const [selectedReport, setSelectedReport] = useState(null);

  const handleReportChange = (report) => {
    setSelectedReport(report);
  };

  useEffect(() => {
    !selectedReport
      ? navigate(window.location.pathname, { state: null })
      : navigate(
          `/back_office/phish_reports/${selectedReport?.status?.replace('not-phish', 'not_phish')}`,
          { state: selectedReport }
        );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  return (
    <>
      <div className="reports-main">
        <h2 className="reports-main-title">
          Reported phish
        </h2>

        {!loading
          ? <>
              <div className='reports-main-container'>
                <nav className="reports-nav">
                  <NavLink
                    onClick={() => setSelectedReport(null)}
                    to="/back_office/phish_reports/new"
                    className={({ isActive }) => cn('reports-item',
                      { 'reports-item--active': isActive })}
                  >
                    New ({phishReports?.new || 0})
                  </NavLink>

                  <NavLink
                    onClick={() => setSelectedReport(null)}
                    to="/back_office/phish_reports/phish"
                    className={({ isActive }) => cn('reports-item',
                      { 'reports-item--active': isActive })}
                  >
                    Phish ({phishReports?.phish || 0})
                  </NavLink>

                  <NavLink
                    onClick={() => setSelectedReport(null)}
                    to="/back_office/phish_reports/not_phish"
                    className={({ isActive }) => cn('reports-item',
                      { 'reports-item--active': isActive })}
                  >
                    Not phish ({phishReports?.notPhish || 0})
                  </NavLink>
                  
                  <NavLink
                    onClick={() => setSelectedReport(null)}
                    to="/back_office/phish_reports/spam"
                    className={({ isActive }) => cn('reports-item',
                      { 'reports-item--active': isActive })}
                  >
                    Spam ({phishReports?.spam || 0})
                  </NavLink>
                </nav>

                <div className={cn('phish-reports-select', {
                  'phish-reports-select--disabled': loading || !phishReports?.userStoriesSelect?.length
                })}>
                  <label className='userslist-select-label'>
                    <ReactSelect
                      classNames={'select-phish-reports'}
                      options={phishReports?.userStoriesSelect || []}
                      optionComponent={ PhishReportsOption }
                      placeholder={'Search a report...'}
                      value={selectedReport}
                      onChange={handleReportChange}
                      isSearchable={true}
                      isDisabled={loading || !phishReports?.userStoriesSelect?.length}
                      isClearable={true}
                      optionHeight={82}
                      rows={5}
                    />
                  </label>
                </div>
              </div>

              <Outlet context={selectedReport} />
            </>
          : <Loader />}
      </div>
  </>
  )
}
