import React, { useState, useEffect, useCallback } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useTitle } from '../../hooks/title.hook';
import cn from 'classnames';
import './UsersGroups.css';
import { useCrypto } from '../../hooks/crypto.hook';
import { useAuth } from '../../hooks/auth.hook';
import settings from '../.././images/icons/gear.svg';
import arrowback from '../../images/icons/arrow red.svg';

export const UsersGroups = () => {
  useTitle('PhishFirewall | Groups');

  const { loading, request } = useHttp();
  const { decryptData } = useCrypto();
  const [card, setCard] = useState(1);
  const [groups, setGroups] = useState(null);
  const [groupsAmount, setGroupsAmount] = useState(0);
  const [scrollWidh, setScrollWidth] = useState(0);
  const [tableWidth, setTableWidth] = useState(0);

  const { token } = useAuth();
  const { group } = useParams();
  const navigate = useNavigate();

  const scrollToGroup = () => {
    if (path === '/back_office/groups/create') {
      document.getElementById(`create`)?.scrollIntoView({
        block: 'end',
        inline: 'end',
        behavior: 'smooth',
      });
    } else {
      document.getElementById(`${group}`)?.scrollIntoView({
        block: 'center',
        inline: 'start',
        behavior: 'smooth',
      });
    }
  };

  const scroll = (direction) => {
    document.getElementById(`groups-nav`)?.scrollBy({
      left: direction === 'left' ? -150 : 150,
      behavior: 'smooth',
    });
    // console.log(scrollPosition)
    //     const position =
    //       scrollPosition + 1;
    //       setScrollPosition(position)
    //     const ids = groups.map(e => e.split(' ').join('-'))
    //         const index = position
    //         console.log(ids[index]);
    //         console.log(document.getElementById(`${ids[index]}`));
    //     document.getElementById(`${ids[index]}`)?.scrollIntoView({
    //       block: 'start',
    //       inline: 'start',
    //       behavior: 'smooth',
    //     });
  };

  const fetchGroupNames = useCallback(async () => {
    try {
      if (token) {
        if (
          !groups?.length ||
          path === '/back_office/groups' ||
          (!groups.includes(group.split('-').join(' ')) &&
            path !== '/back_office/groups/create')
        ) {
          const fetched = await request(
            '/back_office/api/groups/groups',
            'GET',
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );

          const decryptGroups = decryptData(fetched);
          setGroups(decryptGroups);

          return decryptGroups;
        }
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, group]);

  const getGroupsAmount = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/groups/group_count',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        setGroupsAmount(fetched);

        return fetched;
      }
    } catch (error) {}
  }, [token, request]);

  useEffect(() => {
    fetchGroupNames();
  }, [fetchGroupNames]);

  useEffect(() => {
    getGroupsAmount();
  }, [getGroupsAmount]);

  const path = window.location.pathname;

  useEffect(() => {
    if (!groups?.length && path !== '/back_office/groups/create') setCard(1);
    else {
      setCard(2);

      if (!group && path === '/back_office/groups') {
        const param = groups?.length && groups[0]?.split(' ').join('-');
        navigate(`/back_office/groups/${param}`);
      }

      if (
        groups?.length && !group &&
        path === '/back_office/groups/create' &&
        !groups?.includes('New group')
      ) {
        setGroups((prev) => [...prev, 'New group']);
      }
      if (
        path !== '/back_office/groups/create' &&
        groups?.includes('New group')
      ) {
        setGroups((prev) => prev.filter((e) => e !== 'New group'));
      }
    }
    if (!groups?.includes('New group')) scrollToGroup();
    setScrollWidth(document.getElementById(`groups-nav`)?.scrollWidth);
    setTableWidth(document.getElementById(`nav`)?.offsetWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, groups, group, navigate, path]);
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!loading && (
        <div className="group-main">
          {path === '/back_office/groups/mapping' && (
            <NavLink className="groups-back-link" to={'/back_office/groups'}>
              Back to Groups
            </NavLink>
          )}
          <div className="header-container">
            <h2 className="group-main-title">
              {path === '/back_office/groups/mapping'
                ? 'Groups mapping'
                : 'Groups'}
            </h2>

            <div className="group-buttons-container">
              {path !== '/back_office/groups/mapping' &&
                groups?.length > 0 &&
                groups?.length < groupsAmount &&
                !groups?.includes('New group') && (
                  <NavLink to={'/back_office/groups/create'}>
                    <button
                      className="group-button"
                      onClick={() => {
                        setGroups((prev) => [...prev, 'New group']);
                        scrollToGroup();
                      }}
                      disabled={loading}
                      type="submit"
                    >
                      Add new group
                    </button>
                  </NavLink>
                )}

              {groups?.length > 0 && path !== '/back_office/groups/mapping' && (
                <NavLink
                  to={'/back_office/groups/mapping'}
                  className="group-button-link"
                >
                  <button
                    onClick={() => {
                      setGroups((prev) => [...prev, 'New group']);
                    }}
                    disabled={loading}
                    type="submit"
                  >
                    <img src={settings} alt="settings"></img>
                    Group mapping
                  </button>
                </NavLink>
              )}
            </div>
          </div>

          {groups?.length === 0 &&
            card === 1 &&
            path !== '/back_office/groups/mapping' && (
              <div>
                <p className="groups-description">There are no groups yet.</p>

                <div className="group-buttons-container">
                  <NavLink to={'/back_office/groups/create'}>
                    <button
                      className="group-button"
                      onClick={() => setCard(2)}
                      disabled={loading}
                      type="submit"
                    >
                      Add new group
                    </button>
                  </NavLink>
                  <NavLink
                    to={'/back_office/groups/mapping'}
                    className="group-button-link"
                  >
                    <button
                      onClick={() => {
                        setGroups((prev) => [...prev, 'New group']);
                      }}
                      disabled={loading}
                      type="submit"
                    >
                      <img src={settings} alt="settings"></img>
                      Group mapping
                    </button>
                  </NavLink>
                </div>
              </div>
            )}
          {groups?.length > 0 && path !== '/back_office/groups/mapping' && (
            <nav className="groups-nav" id="nav">
              {tableWidth < scrollWidh && (
                <button
                  className="scroll-button"
                  onClick={() => scroll('left')}
                >
                  <img
                    src={arrowback}
                    className="colored-arrow"
                    style={{ transform: 'rotate(-270deg)', width: '24px' }}
                    alt="back"
                  ></img>
                </button>
              )}
              
              <div className="groups-nav" id="groups-nav">
                {groups?.map((g, i) => {
                  const param = g.split(' ').join('-');
                  return (
                    <NavLink
                      onClick={scrollToGroup()}
                      key={i}
                      id={g === 'New group' ? 'create' : g.split(' ').join('-')}
                      to={
                        g === 'New group'
                          ? '/back_office/groups/create'
                          : `/back_office/groups/${param}`
                      }
                      className={({ isActive }) =>
                        cn('groups-item', {
                          'groups-item--active':
                            g === 'New group' ? true : isActive,
                        })
                      }
                    >
                      <div className="group-name-container">{g}</div>
                    </NavLink>
                  );
                })}
              </div>

              {tableWidth < scrollWidh && (
                <button
                  className="scroll-button"
                  onClick={() => scroll('right')}
                >
                  <img
                    src={arrowback}
                    style={{ transform: 'rotate(-90deg)', width: '24px' }}
                    alt="back"
                  ></img>
                </button>
              )}
            </nav>
          )}
          <Outlet />
        </div>
      )}
    </>
  );
};
