import React, { useCallback, useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { AuthContext } from '../../context/AuthContext';
import { useCrypto } from '../../hooks/crypto.hook';
import { useHttp } from '../../hooks/http.hook';
import { useOutletContext } from 'react-router-dom';
import { ChartPieMandatoryTraining } from './ChartPieMandatoryTraining';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import { CategoryOption } from '../../components/ReactSelect/ReactSelectOption';


const MandatoryTrainingAdditionalDetails = ({
  selectedTenant, selectedDepartment, selectedLocation,
  categoriesOptions, selectedCategory, setSelectedCategory
}) => {
  const { token, allUsers } = useContext(AuthContext);
  const { encryptData, decryptData } = useCrypto();
  const { requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const categories = useOutletContext();

  const [allTrainingModules, setAllTrainingModules] = useState(0);
  const [trainingModules, setTrainingModules] = useState(0);
  const [modulesPercentage, setModulsPercentage] = useState(0);

  const [enrolledUsers, setEnrolledUsers] = useState(0);
  const [usersPercentage, setUsersPercentage] = useState(0);

  const [totallyCompliatUsers, setTotallyCompliatUsers] = useState(0);
  const [totallyCompliantPercentage, setTotallyCompliantPercentage] = useState(0);
  const [nonCompliantUsers, setNonCompliantUsers] = useState(0);
  const [nonCompliantPercentage, setNonCompliantPercentage] = useState(0);

  const [mandatoryTrainingChartData, setMandatoryTrainingChartData] = useState({});

  const fetchAdditionalDetails = useCallback(async (
    selectedTenant, selectedDepartment, selectedLocation, selectedCategory
  ) => {
    try {
      if (token) {
        const data = encryptData({
          tenantId: selectedTenant?.value || null,
          department: selectedDepartment?.value || null,
          location: selectedLocation?.value || null,
          categoryId: selectedCategory?.value || null
        })
        const additionalDetails = await requestWithSecondaryLoading(
          '/back_office/api/categories/mandatory_training_users/statistics',
          'POST',
          { data },
          { Authorization: `Bearer ${token}` }
        );

        const decryptCatogoriesGeneralData = decryptData(additionalDetails);

        setTrainingModules(decryptCatogoriesGeneralData?.trainingModules);
        setMandatoryTrainingChartData(decryptCatogoriesGeneralData?.chartData);
        setTotallyCompliatUsers(decryptCatogoriesGeneralData?.totallyCompliance);
        setEnrolledUsers(decryptCatogoriesGeneralData?.enrolledUsers);

        setNonCompliantUsers(
          decryptCatogoriesGeneralData?.nonCompliance + decryptCatogoriesGeneralData?.partiallyCompliance
        );
        setTotallyCompliantPercentage(
          Math.round(
            decryptCatogoriesGeneralData?.totallyCompliance / (decryptCatogoriesGeneralData?.enrolledUsers || 1) * 100
          )
        );
        setNonCompliantPercentage(
          Math.round(
            (decryptCatogoriesGeneralData?.nonCompliance + decryptCatogoriesGeneralData?.partiallyCompliance)
              / (decryptCatogoriesGeneralData?.enrolledUsers || 1) * 100
          )
        );

        allUsers?.length && setUsersPercentage(
          Math.round(decryptCatogoriesGeneralData?.enrolledUsers / allUsers?.length * 100)
        );

        return decryptCatogoriesGeneralData
      }
    } catch (error) { }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    fetchAdditionalDetails(selectedTenant, selectedDepartment, selectedLocation, selectedCategory);
  }, [fetchAdditionalDetails, selectedDepartment, selectedLocation, selectedTenant, selectedCategory]);

  useEffect(() => {
    if (categories?.length) {
      setAllTrainingModules(categories?.length);

      if (trainingModules) {
        setModulsPercentage(Math.round(trainingModules / categories?.length * 100));
      }
    }
  }, [categories, trainingModules]);

  const handleCategoryChange = data => setSelectedCategory(data);

  return (
    <div className="categories-summary-additional-container">
      <div className={cn('categories-summary-additional-stats', {
        'categories-summary-additional-stats-download': secondaryLoading
      })}>
        <div>
          <h3>Training Engagement</h3>
          <div className={cn('input-container-mandatory-training-item', {
            'input-container-mandatory-training-item--disabled': secondaryLoading || !categoriesOptions?.length
          })}>
            <div className='input-field-mandatory-training-category'>
              <label className={cn('category-select', {'category-select--all': !selectedCategory.value})}>
                <ReactSelect
                  options={categoriesOptions}
                  optionComponent={CategoryOption}
                  placeholder={'All Trainings'}
                  classNames={'option-select-category'}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  isSearchable={true}
                  isClearable={false}
                  isDisabled={secondaryLoading || !categoriesOptions?.length}
                  optionHeight={32}
                  rows={10}
                />
              </label>
            </div>
          </div>
        </div>

        <div className='categories-summary-additional-stats--graph'>
          <div className="stats-container">
            <div className="stat">
              <div className='stat-percentage'>
                {modulesPercentage}%
              </div>
              <div className='stat-percentage-desc'>Issued Training</div>
            </div>
            <div className="stat">
              <div className='stat-percentage'>
                {usersPercentage}%
              </div>
              <div className='stat-percentage-desc'>On Track</div>
            </div>
          </div>

          <div className="progress-container progress-container--first">
            <div className="progress-item">
              <div className="progress-bar">
                <div className="progress" style={{ width: `${modulesPercentage}%` }}>
                  <div className="progress-text">
                    {trainingModules}/{allTrainingModules || 0} Modules
                  </div>
                </div>
              </div>
            </div>
            <div className="progress-item">
              <div className="progress-bar">
                <div className="progress" style={{ width: `${usersPercentage}%` }}>
                  <div className="progress-text">
                    {enrolledUsers} Enrolled {enrolledUsers === 1 ? 'User' : 'Users'}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="stats-container">
            <div className="stat">
              <div className='stat-percentage'>
                {totallyCompliantPercentage}%
              </div>
              <div className='stat-percentage-desc'>Compliant</div>
            </div>
            <div className="stat">
              <div className={cn('stat-percentage stat-percentage--non-compliant', {
                'stat-percentage--non-compliant--moderate': nonCompliantPercentage > 0,
                'stat-percentage--non-compliant--high': nonCompliantPercentage > 50
              })}>
                {nonCompliantPercentage}%
              </div>
              <div className='stat-percentage-desc'>Not Compliant</div>
            </div>
          </div>

          <div className="progress-container">
            <div className="progress-item">
              <div className="progress-bar">
                <div className="progress" style={{ width: `${totallyCompliantPercentage}%` }}>
                  <div className="progress-text">
                    {totallyCompliatUsers} {totallyCompliatUsers === 1 ? 'User' : 'Users'}
                  </div>
                </div>
              </div>
            </div>
            <div className="progress-item">
              <div className="progress-bar">
                <div className={cn("progress progress--non-compliant", {
                  'progress--non-compliant--moderate': nonCompliantPercentage > 0,
                  'progress--non-compliant--high': nonCompliantPercentage > 50
                })} style={{ width: `${nonCompliantPercentage}%` }}>
                  <div className="progress-text">
                    {nonCompliantUsers} {nonCompliantUsers === 1 ? 'User' : 'Users'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChartPieMandatoryTraining
        chartData={mandatoryTrainingChartData}
        loading={secondaryLoading}
        title={'User compliance'}
      />
    </div>
  );
};

export default MandatoryTrainingAdditionalDetails;
