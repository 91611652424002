import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import cn from 'classnames';
import './SideBar.css';
import { CustomizedTooltipDisabled } from '../Tooltips/Tooltip';
import arrowdown from '../.././images/icons/caret-down.svg';

export const SideBar = () => {
  const { user, phishReports, allUsers } = useContext(AuthContext);
  const [adminActive, setAdminActive] = useState(true);
  const [activityActive, setActivityActive] = useState(window.location.href.includes('/back_office/activity'));
  const [reportsActive, setReportsActive] = useState(window.location.href.includes('/back_office/phish_reports'));
  const [integrationsActive, setIntegrationsActive] = useState(window.location.href.includes('/back_office/phish_reports'));
  const [emailsActive, setEmailsActive] = useState(window.location.href.includes('/back_office/email_settings') || window.location.href.includes('/back_office/email_templates'));

  useEffect(() => {
    if (phishReports.new) setReportsActive(true);
  }, [phishReports.new]);

  useEffect(() => {
    setTimeout(() => {
      if (
        !window.location.pathname.includes('/back_office/user')
        && !window.location.pathname.includes('back_office/exclusions')
        && !window.location.pathname.includes('back_office/groups')
        && !window.location.pathname.includes('back_office/categories')
      ) {
          setAdminActive(false);
      };
    }, 500);
  }, []);

  return (
    <div className='sidebar'>
      <div className='bar'>
        <ul className='bar-list'>
          <li className='bar-item'>
            <div className='bar-item-action' onClick={() => setAdminActive(prevState => !prevState)}>
              <button className='item-button'>
                  Administration
                </button>
              {!adminActive
                ? <img src={arrowdown} alt='arrow down' />
                : <img style={{'transform': 'rotate(180deg)'}} src={arrowdown} alt='arrow up' />
              }
            </div>

            {adminActive &&
              <ul className='bar-item-list'>
                <li className='list-item'>
                  <NavLink
                    className={({ isActive }) => cn('bar-link', {
                      'bar-link-active sync-link-active': isActive,
                      'bar-link bar-link-inactive sync-link-inactive': !isActive,
                    })}
                    to="/back_office/user/sync"
                  >
                    Sync
                  </NavLink>
                </li>
                <li className='list-item'>
                  {!user?.syncAD && !user?.syncGoogle && !user?.syncCSV && !allUsers.length &&
                    <CustomizedTooltipDisabled
                      position={'right'}
                      text1={'First, you must configure'}
                      text2={'at least one of the user'}
                      text3={'synchronization methods'}
                    />
                  }
                  <NavLink
                    className={({ isActive }) => cn('bar-link', {
                      'bar-link-active ad-link-active': isActive,
                      'bar-link bar-link-inactive ad-link-inactive': !isActive,
                      'item--disabled': !user?.syncAD && !user?.syncGoogle && !user?.syncCSV && !allUsers.length
                    })}
                    to="/back_office/user/users_database"
                  >
                    User Database
                  </NavLink>
                </li>
                <li className='list-item'>
                  {user?.paymentPlanId !== 4 &&
                    <CustomizedTooltipDisabled
                      position={'right'}
                      text1={'This feature is available'}
                      text2={'in the Enterprise plan'}
                    />
                  }
                  <NavLink
                    className={({ isActive }) => cn('bar-link', {
                      'bar-link-active groups-link-active': isActive,
                      'bar-link bar-link-inactive groups-link-inactive': !isActive,
                      'item--disabled': user?.paymentPlanId !== 4
                    })}
                    to={"/back_office/groups"}
                  >
                    Groups
                  </NavLink>
                </li>
                
                {/* Still in development */}
                <li className='list-item'>
                  {user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4 && 
                    <CustomizedTooltipDisabled
                      position={'right'}
                      text1={'This feature is available'}
                      text2={'in the Professional'}
                      text3={'and Enterprise plan'}
                    />
                  }
                  <NavLink
                    className={({ isActive }) => cn('bar-link', {
                      'bar-link-active mandatory-link-active': isActive,
                      'bar-link bar-link-inactive mandatory-link-inactive': !isActive,
                      'item--disabled': user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4,
                      'development-in-progress': false
                    })}
                    to={"/back_office/categories"}
                  >
                    Mandatory Training
                  </NavLink>
                </li>
                
                <li className='list-item'>
                  <NavLink
                    className={({ isActive }) => cn('bar-link', {
                      'bar-link-active exc-link-active': isActive,
                      'bar-link bar-link-inactive exc-link-inactive': !isActive,
                    })}
                    to={"/back_office/exclusions"}
                  >
                    Exclusions
                  </NavLink>
                </li>
              </ul>
            }
          </li>
          <li className='bar-item'>
            <div className='bar-item-action' onClick={() => setActivityActive(prevState => !prevState)}>
              <button className='item-button'>
                Analytics
              </button>
              {!activityActive
                ? <img src={arrowdown} alt='arrow down' />
                : <img style={{'transform': 'rotate(180deg)'}} src={arrowdown} alt='arrow up' />
              }
            </div>
            
            {activityActive &&
              <ul className='bar-item-list'>
                <li className='list-item'>
                  <NavLink
                    className={({ isActive }) => cn('bar-link', {
                      'bar-link-active act-link-active': isActive,
                      'bar-link bar-link-inactive act-link-inactive': !isActive,
                    })}
                    to={"/back_office/activity/users_actions"}
                  >
                    User actions
                  </NavLink>
                </li>
                <li className='list-item'>
                  <NavLink
                    className={({ isActive }) => cn('bar-link', {
                      'bar-link-active history-link-active': isActive,
                      'bar-link bar-link-inactive history-link-inactive': !isActive,
                    })}
                    to={"/back_office/activity/mailing_history"}
                  >
                    Mailing history
                  </NavLink>
                </li>
                <li className='list-item'>
                  {user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4 &&
                      <CustomizedTooltipDisabled
                        position={'right'}
                        text1={'This feature is available'}
                        text2={'in the Professional'}
                        text3={'and Enterprise plan'}
                      />
                    }
                    <NavLink
                      className={({ isActive }) => cn('bar-link', {
                        'bar-link-active analytics-link-active': isActive,
                        'bar-link bar-link-inactive analytics-link-inactive': !isActive,
                        'item--disabled': user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4
                      })}
                      to={"/back_office/activity/user_analytics"}
                    >
                      Analytics
                    </NavLink>
                </li>
              </ul>
            }
          </li>
          
          <li className='bar-item'>
            <div className='bar-item-action' onClick={() => setReportsActive(prevState => !prevState)}>
              <button className='item-button'>
                Phish reports
              </button>
              {!reportsActive
                ? <img src={arrowdown} alt='arrow down' />
                : <img style={{'transform': 'rotate(180deg)'}} src={arrowdown} alt='arrow up' />}
            </div>

           {reportsActive && <ul className='bar-item-list'>
              <li className='list-item'>
                {user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4 && 
                  <CustomizedTooltipDisabled
                    position={'right'}
                    text1={'This feature is available'}
                    text2={'in the Professional'}
                    text3={'and Enterprise plan'}
                  />
                }
                <NavLink
                  className={({ isActive }) => cn('bar-link', {
                    'bar-link-active report-link-active': isActive,
                    'bar-link bar-link-inactive report-link-inactive': !isActive,
                    'item--disabled': user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4
                  })}
                  to={"/back_office/phish_reports"}
                >
                  <div className='bar-report-notification'>
                    Reported phish
                    {phishReports.new
                      ? <div className={cn({
                          'more-than-9': phishReports.new > 9,
                          'more-than-99': phishReports.new > 99,
                          'more-than-999': phishReports.new > 999,
                        })}>
                          <span>{phishReports.new}</span>
                        </div>
                      : null}
                  </div>
                </NavLink>
              </li>
            </ul>}
          </li>

          <li className='bar-item'>
            <div className='bar-item-action' onClick={() => setIntegrationsActive(prevState => !prevState)}>
              <button className='item-button'>
                Integrations
              </button>
              {!integrationsActive
                ? <img src={arrowdown} alt='arrow down' />
                : <img style={{'transform': 'rotate(180deg)'}} src={arrowdown} alt='arrow up' />
              }
            </div>

            {integrationsActive &&
              <ul className='bar-item-list'>
                <li className='list-item'>
                  {(user?.paymentPlanId !== 4 || user?.role !== 'owner') &&
                    <CustomizedTooltipDisabled
                      position={'right'}
                      text1={'This feature is available'}
                      text2={'in the Enterprise plan'}
                      text3={'and for Owner'}
                    />
                  }
                  <NavLink
                    className={({ isActive }) => cn('bar-link', {
                      'bar-link-active api-link-active': isActive,
                      'bar-link bar-link-inactive api-link-inactive': !isActive,
                      'item--disabled': user?.paymentPlanId !== 4 || user?.role !== 'owner'
                    })}
                    to={"/back_office/api_management"}
                  >
                    API management
                  </NavLink>
                </li>
              </ul>
            }
          </li>

          <li className='bar-item'>
            <div className='bar-item-action' onClick={() => setEmailsActive(prevState => !prevState)}>
              <button className='item-button'>
                Emails
              </button>
              {!emailsActive
                ? <img src={arrowdown} alt='arrow down' />
                : <img style={{'transform': 'rotate(180deg)'}} src={arrowdown} alt='arrow up' />
              }
            </div>

            {emailsActive &&
              <ul className='bar-item-list'>
                <li className='list-item'>
                  {user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4 && 
                    <CustomizedTooltipDisabled
                      position={'right'}
                      text1={'This feature is available'}
                      text2={'in the Professional'}
                      text3={'and Enterprise plan'}
                    />
                  }
                  <NavLink
                    className={({ isActive }) => cn('bar-link', {
                      'bar-link-active email-link-active': isActive,
                      'bar-link bar-link-inactive email-link-inactive': !isActive,
                      'item--disabled': user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4
                    })}
                    to={"/back_office/email_settings"}
                  >
                    Email Settings
                  </NavLink>
                  <NavLink
                    className={({ isActive }) => cn('bar-link', {
                      'bar-link-active lib-act-link-active': isActive,
                      'bar-link bar-link-inactive lib-act-link-inactive': !isActive,
                      // 'item--disabled': user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4
                    })}
                    to={"/back_office/email_templates"}
                  >
                    Email Templates
                  </NavLink>
                </li>
              </ul>
            }
          </li>
        </ul>
      </div>

      <div className='sidebar-instruction'>
        <span>Not Getting Training?<br/> Be Sure to Whitelist!</span>
        <a
          target="_blank"
          rel="noreferrer"
          href='https://www.phishfirewall.com/phishfirewall-onboarding-delivery/'
        >
          <button>
            White List Guide
          </button>
        </a>
      </div>
    </div>
  )
}
