import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import info from '../../images/icons/Info Circle.svg'
import './Tooltip.css';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#E6E6E6",
    color: "#343232",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0.02em",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#E6E6E6'
  },
  [`& .${tooltipClasses.tooltip} a`]: {
    color: "#343232",
    fontWeight: "600",
    lineHeight: "18px",
    letterSpacing: "0.02em",
    fontSize: theme.typography.pxToRem(11),
  }
}));

export const CustomizedTooltip = ({ link, name }) => {
  return (
    <HtmlTooltip
      arrow
      placement="right"
      title={
        <React.Fragment>
          <span>
            Need help finding<br/>{name}?
          </span><br/>
          <a
            target="_blank"
            rel="noreferrer"
            href={link}
          >
            Click here
          </a>
        </React.Fragment>
      }
    >
      <img className="tooltip-help" src={info} alt='info'></img>
    </HtmlTooltip>
  );
};

export const CustomizedTooltipDescription = ({ title, text1, text2, text3 }) => {
  return (
    <HtmlTooltip
      arrow
      placement="top"
      title={
        <React.Fragment>
          <span>
            {text1}<br/>
            {text2}<br/>
            {text3}
          </span>
        </React.Fragment>
      }
    >
      <p className="exclusions-item exclusions-item--inactive">
        {title}
      </p>
    </HtmlTooltip>
  );
};

export const CustomizedTooltipDisabled = ({ position, text1, text2, text3, button }) => {
  return (
    <HtmlTooltip
      arrow
      placement={position}
      title={
        <React.Fragment>
          <span>
            {text1}<br/>
            {text2}<br/>
            {text3}
          </span>
        </React.Fragment>
      }
    >
      {button ? <div>{button}</div> : <div className="disabled-item"></div>}
    </HtmlTooltip>
  );
};

export const CustomizedTooltipDisabledMonthly = ({ position, text }) => {
  return (
    <HtmlTooltip
      arrow
      placement={position}
      title={
        <React.Fragment>
          <span>
            {text}
          </span>
        </React.Fragment>
      }
    >
      <div className="disabled-item-monthly"></div>
    </HtmlTooltip>
  );
};

export const CustomizedTooltipSeats = ({ text1, text2, text3 }) => {
  return (
    <HtmlTooltip
      arrow
      placement="right"
      title={
        <React.Fragment>
          <span>
            {text1}
            <strong>{text2}</strong>
            {text3}
          </span>
        </React.Fragment>
      }
    >
      <img className="tooltip-help" src={info} alt='info'></img>
    </HtmlTooltip>
  );
}

export const CustomizedTooltipFalsePositive = ({ text1, text2, component, showFragment, position='left' }) => {
  return (
    <HtmlTooltip
      arrow
      placement={position}
      title={showFragment
        ? <React.Fragment>
            <div className='false-positive-tooltip'>
              {text1 ? <span>{text1}<br/></span>: null}
              <strong>{text2}</strong>
            </div>
          </React.Fragment>
        : null
      }
    >
      {component}
    </HtmlTooltip>
  );
};

export const CustomizedTooltipDateRange = ({ text1, text2, component, showFragment, position='left' }) => {
  return (
    <HtmlTooltip
      arrow
      placement={position}
      title={showFragment
        ? <React.Fragment>
            <div className='date-range-tooltip'>
              {text1 ? <span>{text1}<br/></span>: null}
              <span>{text2}</span>
            </div>
          </React.Fragment>
        : null
      }
    >
      {component}
    </HtmlTooltip>
  );
};

export const CustomizedTooltipEditTenantPermissions = (props) => {
  return (
    <HtmlTooltip
      arrow
      placement="left"
      title={props.showFragment
         ? <React.Fragment>
            <div>
              <p className='permissions-tooltip'>{props.text1}</p>

              {props.text2condition
                ? <span className="permissions-check">&#10003;</span>
                : <span className="permissions-cross">&#10007;</span>
              }
              <strong>
                {props.text2}
              </strong><br/>

              {props.text3condition
                ? <span className="permissions-check">&#10003;</span>
                : <span className="permissions-cross">&#10007;</span>
              }
              <strong>
                {props.text3}
              </strong><br/>

              {props.text4condition
                ? <span className="permissions-check">&#10003;</span>
                : <span className="permissions-cross">&#10007;</span>
              }
              <strong>
                {props.text4}
              </strong>
            </div>
          </React.Fragment>
        : null
      }
    >
      {props.component}
    </HtmlTooltip>
  );
}

export const CustomizedTooltipInfo = ({ text }) => {
  return (
    <HtmlTooltip
      arrow
      placement="right"
      title={
        <React.Fragment>
          <span>
            {text}
          </span><br/>
        </React.Fragment>
      }
    >
      <img className="tooltip-help" src={info} alt='info'></img>
    </HtmlTooltip>
  );
};

export const CustomizedTooltipSendMail = ({ text, component, placement }) => {
  return (
    <HtmlTooltip
      arrow
      placement={placement}
      title={
        <React.Fragment>
          <span>
            {text}
          </span>
        </React.Fragment>
      }
    >
      {component}
    </HtmlTooltip>
  );
};
