import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useSortableData } from '../../hooks/sort.hook';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import { useCrypto } from '../../hooks/crypto.hook';
import './GroupsMapping.css';
import { NavLink } from 'react-router-dom';
import azure from '../../images/icons/Azure.svg';
import google from '../../images/icons/Google (colors).svg';
import Select, { components } from 'react-select';
import colourStyles from '../../styles/colour-style';
import { AuthContext } from '../../context/AuthContext';
import Modal from 'react-modal';
import { CustomizedTooltipDisabled } from '../../components/Tooltips/Tooltip';
import { syncOptions } from '../../config/config';
// import { newMappingCard } from "../../components/Groups/Mapping/NewMappingCard"
import { useLocation } from 'react-router-dom';
import { GroupsOption } from '../../components/ReactSelect/ReactSelectOption';
import AsyncSelect from 'react-select/async';
import ReactSelect from '../../components/ReactSelect/ReactSelect';

export const GroupsMapping = () => {
  const { loading, request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const [tenants, setTenants] = useState([]);
  const [newMappingCard, setNewMappingCard] = useState(false);
  const [selectedSyncType, setSyncType] = useState(syncOptions[0]);
  const [selectedTenant, setSelectedTenant] = useState();
  const [selectedSyncGroups, setSyncGroups] = useState([]);
  const [systemGroupsOptionList, setSystemGrousOptionList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(0);
  const [syncModal, setSyncModal] = useState(false);
  const [editedTenant, setEditedTenant] = useState();
  const [editedGroup, setEditedGroup] = useState();
  const [editedSyncGroups, setEditedSyncGroups] = useState();
  const [syncTypes, setSyncTypes] = useState(syncOptions);
  const [exclusions, setExclusions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { showToastMessage, allUsers, groupsLogs } = useContext(AuthContext);
  const { items, requestSort, sortConfig } = useSortableData(tenants);
  const [initialGroups, setInitialGroups] = useState([]);
  const [step, setStep] = useState(1);
  const [azureGroups, setAzureGroups] = useState({});

  const location = useLocation();
  const { state } = location;

  const syncOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')} groups-map-tenant-label`}>
          {label}
        </div>
      </components.Option>
    );
  };

  const closeModal = () => {
    setAzureGroups({});
    setStep(1)
    setSyncModal(false);
  }
  const azureTenants = tenants?.filter((t) => t.type === 'azure');
  const googleTenants = tenants?.filter((t) => t.type === 'google');
  const azureTenantsArray = azureTenants?.map((e) => {
    return {
      label: e.name,
      id: e.id,
      value: e.name.split(' ').join('_'),
    };
  });
  const googleTenantsArray = googleTenants?.map((e) => {
    return {
      label: e.name,
      id: e.id,
      value: e.name.split(' ').join('_'),
    };
  });

  const tenantsOption = (props) => {
    const { label } = props.data;

    return (
      <components.Option {...props}>
        <div className={`groups-map-tenant-label`}>{label}</div>
      </components.Option>
    );
  };

  const customStyles = {
    content: {
      padding: '0',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 2px 8px rgba(52, 50, 50, 0.15)',
      borderRadius: '4px',
      overflow: 'visible',
    },
  };

  const getUsersGroups = (tenant) => {
    if (tenant) {
      const { name, type } = tenant;
      const groups = groupsLogs[type]?.find(
        (l) => l.tenantName === name
      )?.userGroups;

      return groups;
    }
  };

  const getUsersOptions = (tenant, groupsIds = []) => {
    if (tenant.type === 'azure') {
      const userEmails = editedSyncGroups?.map((g) => azureGroups[g.value]).flat();
      const uniqueEmails = [...new Set(userEmails)];
      const uniqueUsers = allUsers?.filter((u) => uniqueEmails?.includes(u.email));
      const options = uniqueUsers?.map((u) => {
        return {
          value: u.email,
          label: `${u.firstName} ${u.secondName} <${u.email}>`,
          key: u.email,
        };
      });

      return options;
    }
    const groups = getUsersGroups(tenant);
    const selectedGroups = groups?.filter((g) => groupsIds?.includes(g.id));
    const groupsUsersEmails = selectedGroups
      ?.map((g) => g.members)
      .flat()
      .map((m) => m.mail);

    const groupsUsersFromLog = allUsers?.filter((u) =>
      groupsUsersEmails?.includes(u.email)
    );

    if (!groupsUsersFromLog.length) {
      const groupsUsers = allUsers?.filter((u) =>
        u.syncGroups.some((g) => groupsIds?.includes(g))
      );

      const options = groupsUsers?.map((u) => {
        return {
          value: u.email,
          label: `${u.firstName} ${u.secondName} <${u.email}>`,
          key: u.email,
        };
      });

      return options;
    } else {
      const options = groupsUsersFromLog?.map((u) => {
        return {
          value: u.email,
          label: `${u.firstName} ${u.secondName} <${u.email}>`,
          key: u.email,
        };
      });

      return options;
    }

  };

  const getGroupsNames = (syncType, tenant) => {
    const groups = getUsersGroups({ name: tenant, type: syncType });
    const groupsOptions = groups
      ?.map((e) => {
        return { value: e.id, label: e.name, key: e.id };
      });

    return groupsOptions;
  };

  const getExclusionUsers = (exclusions) => {
    const excludedEmails = exclusions?.map((e) => e.userEmail);
    const excludedUsers = allUsers.filter((u) =>
      excludedEmails?.includes(u.email)
    );

    const options = excludedUsers.map((u) => {
      return {
        value: u.email,
        label: `${u.firstName} ${u.secondName} <${u.email}>`,
        key: u.id,
      };
    });

    return options;
  };

  const getExclusions = async (groupName) => {
    try {
      if (token) {
        const fetched = await requestWithSecondaryLoading(
          `/back_office/api/groups/${groupName
            .split(' ')
            .join('-')}/exclusions`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptExclusions = decryptData(fetched);
        const exclusionOptions = getExclusionUsers(decryptExclusions);

        setExclusions(exclusionOptions);

        return decryptExclusions;
      }
    } catch (error) { }
  };

  const addExclusions = async (groupName, users) => {
    try {
      const payload = {
        selectedUsers: users.map((u) => u.value),
        tenant: {
          name: editedTenant.name || editedTenant.label,
          id: editedTenant.id,
        },
      };

      if (token) {
        const fetched = await request(
          `/back_office/api/groups/${groupName
            .split(' ')
            .join('-')}/exclusions`,
          'POST',
          payload,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptExclusions = decryptData(fetched);
        const tenantExclusions = decryptExclusions.filter(
          (e) => e.tenantName === editedTenant.name
        );
        const exclusionOptions = getExclusionUsers(tenantExclusions);
        setExclusions(exclusionOptions);

        return decryptExclusions;
      }
    } catch (error) { }
  };

  const syncGroupsVariations = getGroupsNames(
    selectedSyncType?.value,
    selectedTenant?.label
  );

  const syncGroupsOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')} groups-map-tenant-label`}>
          {label}
        </div>
      </components.Option>
    );
  };

  const fetchAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/user/get_all_tenants',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptUsers = decryptData(fetched);
        setTenants(decryptUsers.filter((t) => t.type !== 'csv'));
        const syncTypesOptions = decryptUsers.map((t) => t.type);
        const syncOptions = syncTypes.filter((t) =>
          syncTypesOptions.includes(t.value)
        );

        setSyncTypes(syncOptions);
        setSyncType(syncOptions[0]);

        const firstTenant = decryptUsers?.filter(
          (t) => t.type === syncOptions[0].value
        )[0];

        handleTenantChange({
          value: firstTenant?.name.split(' ').join('-'),
          label: firstTenant?.name,
          id: firstTenant?.id,
        });

        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const getGroups = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(getGroupsFunction(inputValue));
      }, 2000);
    });

  const getGroupsFunction = async (inputValue) => {
    try {
      if (token && selectedTenant.id && selectedSyncType.value) {
        const fetched = await requestWithSecondaryLoading(
          `/back_office/api/groups/sync_group_list_azure?groupQuery=${inputValue}&tenantId=${selectedTenant?.id}&syncType=${selectedSyncType?.value}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const data = decryptData(fetched)

        return data.map(e => {
          return {
            value: e.id,
            label: e.displayName,
          }
        });
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getGroupMembers = useCallback(async () => {
    try {
      if (editedTenant.type === 'azure') {
        for (const group of editedSyncGroups) {
          const groupId = group.value;
          if (!azureGroups[groupId]) {
            const fetched = await requestWithSecondaryLoading(
              `/back_office/api/groups/get_groups_members_azure?groupId=${groupId}&tenantId=${selectedTenant?.id}`,
              'GET',
              null,
              {
                Authorization: `Bearer ${token}`,
              }
            );
            const data = decryptData(fetched)

            setAzureGroups((prev) => { return { ...prev, [groupId]: data } });
          }
        }
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, selectedTenant?.id, editedSyncGroups]);

  const getInitialGroups = useCallback(
    async () => {
      try {
        if (token && selectedTenant?.id && selectedSyncType?.value === 'azure') {
          const fetched = await requestWithSecondaryLoading(
            `/back_office/api/groups/sync_group_list_azure?groupQuery=${inputValue}&tenantId=${selectedTenant?.id}&syncType=${selectedSyncType?.value}&limit=999`,
            'GET',
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );
          const data = decryptData(fetched)

          const groups = data.map(e => {
            return {
              value: e.id,
              label: e.displayName,
            }
          });
          setInitialGroups(groups);
        }
      } catch (error) { }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [token, request, selectedTenant?.id]);

  const changeGroupMapping = async ({
    destGroup,
    selectedUsersGroups,
    tenantName,
    syncType,
  }) => {
    const selectedData = {
      selectedUsersGroups,
      destGroup,
      tenantName,
      syncType,
      exclusionsEmails: exclusions.map((e) => e.value),
    };

    if (token) {
      const data = encryptData(selectedData);
      const response = await request(
        `/back_office/api/groups/create/groups_map`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setSyncGroups([]);
      setEditedSyncGroups([]);
      setSelectedTenant();
      setEditedTenant();
      setSelectedOptions([]);
      await fetchAllTenants();
      showToastMessage(response.error, response.message);
    }
  };

  const deleteMap = async (destGroup, tenant) => {
    if (token && tenant) {
      const selectedData = {
        destGroup,
        tenantName: tenant.name,
        syncType: tenant.type,
      };
      const data = encryptData(selectedData);
      const response = await request(
        `/back_office/api/groups/change/delete_groups_map`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      await fetchAllTenants();
      showToastMessage(response.error, response.message);
    }
  };

  const handleSyncGroupsChange = (data) => {
    setSyncGroups(data);
  };

  const handleEditSyncGroupsChange = (data) => {
    setEditedSyncGroups(data);
  };

  const handleEditExclusionsChange = (data) => {
    setExclusions(data);
  };

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setEditedTenant();
    setSyncGroups([]);
  };

  const handleSyncTypeChange = (data) => {
    setSyncType(data);
    setSyncGroups([]);
    handleTenantChange(
      data.value === 'azure' ? azureTenantsArray[0] : googleTenantsArray[0]
    );
  };

  const getGroupsOption = () => {
    const groupsMap = tenants.find(
      (t) =>
        t.name === selectedTenant?.label &&
        t.type === selectedSyncType?.value
    )?.groupsMap;

    const groupsNames = systemGroupsOptionList.map((e) => e.label);
    const existingGroupsNames = groupsMap
      ?.filter(
        (e) => groupsNames.includes(e.systemGroup) && e.sourceGroups?.length
      )
      .map((e) => e.systemGroup);
    const newGroups = systemGroupsOptionList?.filter(
      (e) => !existingGroupsNames?.includes(e.label)
    );
    return newGroups;
  };

  const getNamesByIds = (tenant, ids) => {
    if (tenant && ids?.length) {
      const groups = getUsersGroups(tenant);
      const names = groups
        ?.filter((g) => ids.includes(g.id))
        .map((e) => e.name)
        .join(', ');

      return names;
    }
  };

  const fetchGroupList = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/groups/all_groups',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptGroups = decryptData(fetched);
        setSystemGrousOptionList(decryptGroups);

        return decryptGroups;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const Option = (props) => {
    const { label } = props.data;

    return (
      <components.Option {...props}>
        <div className="groups-map-table-label">{label}</div>
      </components.Option>
    );
  };

  useEffect(() => {
    getInitialGroups();
  }, [getInitialGroups]);

  useEffect(() => {
    getGroupMembers();
  }, [getGroupMembers]);

  useEffect(() => {
    fetchGroupList();
  }, [fetchGroupList]);

  useEffect(() => {
    fetchAllTenants();
  }, [fetchAllTenants]);

  useEffect(() => {
    if (state?.name) {
      const tenant = tenants.find((t) => t.name === state.name);
      const syncType = syncTypes.find((t) => t.value === tenant?.type);
      setSyncType(syncType);
      setSelectedTenant({
        label: tenant?.name,
        value: tenant?.name.split(' ').join('-'),
        id: tenant?.id,
      });

      if (state?.syncGroupId) {
        setSyncGroups([{
          value: state.syncGroupId,
          label: getNamesByIds(tenant, [state.syncGroupId]),
        }]);
      }
      setNewMappingCard(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, tenants, syncTypes, groupsLogs]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!loading && (
        <div>
          {/* {newMappingCard && (
            <NewMappingCard
              newMappingCard={newMappingCard}
              setNewMappingCard={setNewMappingCard}
              changeGroupMapping={changeGroupMapping}
            />
          )} */}
          {(newMappingCard || state?.name) && (
            <div className="users-main">
              <div>
                <h3>Add new mapping</h3>

                {!!tenants.length ? (
                  <div className="new-mapping-input">
                    <div
                      className={`new-mapping-select groups-map-select-sync`}
                    >
                      <label className="new-mapping-select-label">
                        Sync method
                        <Select
                          id="select"
                          options={syncTypes}
                          components={{ syncOption }}
                          placeholder="Select sync type"
                          value={selectedSyncType}
                          onChange={handleSyncTypeChange}
                          isSearchable={true}
                          styles={colourStyles}
                        />
                      </label>
                    </div>
                    <div className="new-mapping-select groups-map-select-tenant">
                      <label className="new-mapping-select-label">
                        Tenant
                        <Select
                          id="select"
                          options={
                            selectedSyncType?.value === 'azure'
                              ? azureTenantsArray
                              : googleTenantsArray
                          }
                          components={{ tenantsOption }}
                          placeholder="Select tenant"
                          value={selectedTenant}
                          onChange={handleTenantChange}
                          isSearchable={true}
                          styles={colourStyles}
                        />
                      </label>
                    </div>
                    <div className="new-mapping-select mapping-select-group">
                      <label className="new-mapping-select-label">
                        From {selectedSyncType?.label} group(s)
                        {selectedSyncType.value === 'azure' ? (
                          <AsyncSelect
                            id="select"
                            placeholder="Type to find group"
                            value={selectedSyncGroups}
                            onChange={handleSyncGroupsChange}
                            isSearchable={true}
                            inputValue={inputValue}
                            onInputChange={(e) => setInputValue(e)}
                            styles={colourStyles}
                            loadOptions={getGroups}
                            isLoading={secondaryLoading}
                            isMulti
                            closeMenuOnSelect={true}
                            optionComponent={GroupsOption}
                            defaultOptions={initialGroups}
                            cacheOptions={true}
                          />
                        ) : (
                          <ReactSelect
                            id="select"
                            options={selectedTenant ? syncGroupsVariations : []}
                            optionComponent={GroupsOption}
                            placeholder="Select group"
                            value={selectedSyncGroups}
                            onChange={handleSyncGroupsChange}
                            isLoading={syncGroupsVariations === undefined}
                            isSearchable={true}
                            styles={colourStyles}
                            isMulti
                          />)}
                      </label>
                    </div>
                    <div className={`new-mapping-select mapping-select-group`}>
                      <label className="new-mapping-select-label">
                        To system group
                        <Select
                          id="select"
                          options={getGroupsOption()}
                          components={{ Option }}
                          placeholder="Select group"
                          value={selectedOptions}
                          onChange={handleSelect}
                          isSearchable={true}
                          closeMenuOnSelect={true}
                          styles={colourStyles}
                        />
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className="details-help">
                    <p>You have no integrations yet.</p>
                    <NavLink to={`/back_office/user/sync`}>
                      Please set up your integration first
                    </NavLink>
                  </div>
                )}
              </div>

              <div className="mapping-buttons-container">
                {!tenants.length ? (
                  <CustomizedTooltipDisabled
                    position={'top'}
                    text1={'Will be active after first sync with'}
                    text2={'Azure Active Directory or'}
                    text3={'Google Workspace'}
                    button={
                      <button
                        className="group-button group-button-simple"
                        onClick={async () => {
                          await changeGroupMapping({
                            selectedUsersGroups: selectedSyncGroups,
                            destGroup: selectedOptions.label,
                            tenantName: selectedTenant.label,
                            syncType: selectedSyncType.value,
                          });
                        }}
                        disabled={
                          !selectedTenant ||
                          !selectedOptions ||
                          !selectedSyncGroups.length
                        }
                        type="submit"
                      >
                        Submit
                      </button>
                    }
                  />
                ) : (
                  <button
                    className="group-button group-button-simple"
                    onClick={() => {
                      changeGroupMapping({
                        selectedUsersGroups: selectedSyncGroups,
                        destGroup: selectedOptions.label,
                        tenantName: selectedTenant.label,
                        syncType: selectedSyncType.value,
                      });
                    }}
                    disabled={
                      !selectedTenant ||
                      !selectedOptions ||
                      !selectedSyncGroups?.length
                    }
                    type="submit"
                  >
                    Submit
                  </button>
                )}
                <button
                  className="group-button-secondary"
                  onClick={() => setNewMappingCard(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <div className="users-main">
            <div>
              <div className="audit table">
                <table className="mapping-users-table">
                  <thead className="table-th">
                    <tr>
                      <th className="mapping-table">
                        <h2>Current mapping</h2>
                        {!newMappingCard && (
                          <div>
                            <button
                              className="group-button"
                              onClick={() => {
                                setNewMappingCard(true);
                              }}
                              disabled={loading}
                              type="submit"
                            >
                              Add new mapping
                            </button>
                          </div>
                        )}
                      </th>
                    </tr>

                    {tenants.find((t) => t.groupsMap.length) ? (
                      <tr className="mapping-users-not-clickable mapping-users-tr">
                        <th>System group</th>
                        <th>Sync group(s)</th>
                        <th>Sync method</th>
                        <th>
                          <button
                            type="button"
                            onClick={() => requestSort('tenantName')}
                            className={getClassNamesFor(
                              'tenantName',
                              sortConfig
                            )}
                          >
                            Tenant name
                          </button>
                        </th>
                        <th>Action</th>
                      </tr>
                    ) : (
                      <tr className="groups-description">
                        <th>
                          <p>There is no mapping yet.</p>
                        </th>
                      </tr>
                    )}
                  </thead>

                  <tbody className="table-body">
                    {items.map((tenant) => {
                      return tenant?.groupsMap.map(
                        (group, i) =>
                          !!group.sourceGroups?.length &&
                          getNamesByIds(tenant, group.sourceGroups) && (
                            <tr key={i} className={' mapping-users-tr'}>
                              <td className="mapping-td-email">
                                <span className="group-link">
                                  <NavLink
                                    to={`/back_office/groups/${group.systemGroup
                                      .split(' ')
                                      .join('-')}`}
                                  >
                                    {group.systemGroup}
                                  </NavLink>
                                </span>
                              </td>
                              <td className="mapping-td-email">
                                {getNamesByIds(tenant, group.sourceGroups)}
                              </td>
                              <td className="mapping-sync">
                                {tenant.type === 'azure' ? (
                                  <img
                                    src={azure}
                                    alt="Logo"
                                    className="logo-group"
                                  />
                                ) : (
                                  <img
                                    src={google}
                                    alt="Logo"
                                    className="logo-group"
                                  />
                                )}
                                {tenant.type}
                              </td>
                              <td>{tenant.name}</td>
                              <td className="mapping-td-edit">
                                <i
                                  role="button"
                                  className="map-edit"
                                  onClick={() => {
                                    setAzureGroups({});
                                    setStep(1)
                                    setEditedTenant(tenant);
                                    setEditedGroup(group.systemGroup);
                                    setSyncModal(true);
                                    setEditedSyncGroups(
                                      tenant?.groupsMap
                                        ?.find(
                                          (g) =>
                                            g.systemGroup === group.systemGroup
                                        )
                                        ?.sourceGroups.map((e) => {
                                          return {
                                            value: e,
                                            label: getNamesByIds(tenant, [e]),
                                          };
                                        })
                                    );
                                    getExclusions(group.systemGroup);
                                  }}
                                />
                                <i
                                  role="button"
                                  className="map-delete"
                                  onClick={() =>
                                    deleteMap(group.systemGroup, tenant)
                                  }
                                />
                              </td>
                            </tr>
                          )
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={syncModal}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form className="form modal-form">
          <div className="card-title modal-title">
            Groups synced with {editedGroup} group
            <br></br>
          </div>
          <div className="card-content modal-content">
            <div className="modal-select">
              {step === 1
                && <label className="mapping-select-label">
                  Choose group
                  {editedTenant?.type === 'azure'
                    ? ' in Azure Active Directory'
                    : editedTenant?.type === 'google'
                      ? ' in Google Workspace'
                      : ''}
                  {editedTenant?.type === 'azure' ? (
                    <AsyncSelect
                      id="select"
                      placeholder="Type to find group"
                      onChange={handleEditSyncGroupsChange}
                      value={editedSyncGroups}
                      isSearchable={true}
                      inputValue={inputValue}
                      onInputChange={(e) => setInputValue(e)}
                      styles={colourStyles}
                      loadOptions={getGroups}
                      components={{ syncGroupsOption }}
                      isMulti
                      closeMenuOnSelect={true}
                      defaultOptions={true}
                      cacheOptions={true}
                    />
                  ) : (<Select
                    id="select"
                    options={getGroupsNames(
                      editedTenant?.type,
                      editedTenant?.name
                    )}
                    components={{ syncGroupsOption }}
                    placeholder="Select group"
                    value={editedSyncGroups}
                    onChange={handleEditSyncGroupsChange}
                    isSearchable={true}
                    styles={colourStyles}
                    isMulti
                  />)}
                </label>
              }
              {step === 2 && <label className="mapping-select-label">
                Excluded users
                <Select
                  id="select"
                  options={getUsersOptions(
                    {
                      name: editedTenant?.name,
                      type: editedTenant?.type,
                    },
                    editedSyncGroups?.map((e) => e.value)
                  )}
                  components={{ syncGroupsOption }}
                  placeholder="Select users to exclude"
                  value={!secondaryLoading ? exclusions : []}
                  onChange={handleEditExclusionsChange}
                  isSearchable={true}
                  styles={colourStyles}
                  isLoading={secondaryLoading}
                  isMulti
                />
              </label>}
            </div>
          </div>

          {step === 1 && <div className="modal-button-container">
            <button
              className="group-button-secondary"
              onClick={() => closeModal()}
            >
              Cancel
            </button>

            <button
              className="group-button group-button-simple"
              onClick={() => setStep(2)}
              disabled={!editedSyncGroups?.length}
            >
              Next
            </button>
          </div>
          }
          {step === 2 && <div className="modal-button-container">
            <button
              className="group-button-secondary"
              onClick={() => setStep(1)}
            >
              Back to group selection
            </button>

            <button
              className="group-button group-button-simple"
              onClick={async (e) => {
                await changeGroupMapping({
                  destGroup: editedGroup,
                  selectedUsersGroups: editedSyncGroups,
                  tenantName: editedTenant.name,
                  syncType: editedTenant.type,
                });
                await addExclusions(editedGroup, exclusions);
                closeModal();
              }}
            >
              Submit
            </button>
          </div>}
        </form>
      </Modal>
    </>
  );
};
