import cn from 'classnames';
import moment from 'moment';

export const AllUsersOption = ({
  options,
  children,
  isSelected,
  innerProps
}) => {
  const { label, caption } = options.find(option => option?.label === children) || {};

  return (
    <div
      className={cn("react-select-option", {
        "react-select-option--selected": isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      <div className='react-select-option-label all-users-label'>{label}</div>
      <div className='react-select-caption'>{caption}</div>
    </div>
  )
};

export const GroupsOption = ({
  options,
  children,
  isSelected,
  innerProps
}) => {
  const { label, caption } = options.find(option => option?.label === children) || {};

  return (
    <div
      className={cn("react-select-option", {
        "react-select-option--selected": isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      <div className='react-select-option-label group-label'>{label}</div>
      <div className='react-select-caption'>{caption}</div>
    </div>
  )
};

export const ActionTypeOption = ({
  options,
  children,
  isSelected,
  innerProps
}) => {
  const { label } = options.find(option => option?.label === children) || {};

  return (
    <div
      className={cn("react-select-option", {
        "react-select-option--selected": isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      <div className='react-select-option-label action-type-label'>{label}</div>
    </div>
  )
};

export const EmailTypeOption = ({
  options,
  children,
  isSelected,
  innerProps
}) => {
  const { label } = options.find(option => option?.label === children) || {};

  return (
    <div
      className={cn("react-select-option", {
        "react-select-option--selected": isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      <div className='react-select-option-label email-type-label'>{label}</div>
    </div>
  )
};

export const DepartmentOption = ({
  options,
  children,
  isSelected,
  innerProps
}) => {
  const { value, label } = options.find(option => option?.label === children) || {};

  return (
    <div
      className={cn("react-select-option", {
        "react-select-option--selected": isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      <div className={cn('react-select-option-label department-label', {
        'department-label-all': value === 'all'
      })}>
        {label}
      </div>
    </div>
  )
};

export const TenantOption = ({
  options,
  children,
  isSelected,
  innerProps
}) => {
  const { value, label, syncType } = options.find(option => option?.label === children) || {};

  return (
    <div
      className={cn("react-select-option", {
        "react-select-option--selected": isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      <div className={cn(`react-select-option-label tenant-option-label-${syncType}`, {
        'tenant-label-all': value === 'all'
      })}>
        {label}
      </div>
    </div>
  )
};

export const LocationOption = ({
  options,
  children,
  isSelected,
  innerProps
}) => {
  const { value, label } = options.find(option => option?.label === children) || {};

  return (
    <div
      className={cn("react-select-option", {
        "react-select-option--selected": isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      <div className={cn('react-select-option-label location-label', {
        'location-label-all': value === 'all'
      })}>
        {label}
      </div>
    </div>
  )
};

export const EmailTemplateOption = ({
  options,
  children,
  isSelected,
  innerProps
}) => {
  const { value, label, caption } = options.find(option => option?.label === children) || {};

  return (
    <div
      className={cn("react-select-option", {
        "react-select-option--selected": isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      <div className={cn('react-select-option-label', {
      })}>
        <div className='templates-label'>{label}</div>
        <div className='caption'>Subject: {caption}</div><br />
        <div className='caption'>ID: {value}</div>
      </div>
    </div>
  )
};

export const CategoryOption = ({
  options,
  children,
  isSelected,
  innerProps
}) => {
  const { value, label } = options.find(option => option?.label === children) || {};

  return (
    <div
      className={cn("react-select-option", {
        "react-select-option--selected": isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      <div className={cn('react-select-option-label category-label', {
        'category-label-all': !value
      })}>
        {label}
      </div>
    </div>
  )
};

export const PhishReportsOption = ({
  options,
  children,
  isSelected,
  innerProps
}) => {
  const { value, caption, status, createdAt } = options.find(option => option?.label === children) || {};

  return (
    <div
      className={cn("react-select-option", {
        "react-select-option--selected": isSelected
      })}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
    >
      <div className={cn('react-select-option-label', {
      })}>
        <div className='phish-reports-label'>{value}</div>
        <div className='caption'>Reporter: {caption}</div><br />
        <div className='caption'>Status: {status?.toUpperCase()}</div><br />
        <div className='caption'>Created: {moment(createdAt).utc().format('MM/DD/YYYY HH:mm:ss')}</div>
      </div>
    </div>
  )
};
