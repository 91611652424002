/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import { useSortableData } from '../../hooks/sort.hook';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import colourStyles from '../../styles/colour-style';
import cross from '../../images/icons/Vector.svg';
import Select, { components } from 'react-select';
import { useCrypto } from '../../hooks/crypto.hook';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import './NewUsersGroup.css';
import submit from '../../images/icons/Check.svg';
import Modal from 'react-modal';
import { syncOptions } from '../../config/config';
// import alert from '../../images/icons/alert triangle medium.svg';
import { itemsOnPage } from '../../config/config';
import azure from '../../images/icons/Azure.svg';
import google from '../../images/icons/Google (colors).svg';
import csv from '../../images/icons/File Red.svg';
import { CustomizedTooltipDisabled } from '../../components/Tooltips/Tooltip';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import { GroupsOption } from '../../components/ReactSelect/ReactSelectOption';
import AsyncSelect from 'react-select/async';

export const NewUsersGroup = () => {
  const { loading, request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const { showToastMessage } = useContext(AuthContext);

  const { allUsers, groupsLogs } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [syncGroups, setSyncGroupsOptionList] = useState({});
  const [selectedOptions, setSelectedOptions] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [card, setCard] = useState(1);
  const [syncModal, setSyncModal] = useState(false);
  const [selectedUsersShown, setSelectedUsersShown] = useState(false);
  const [selectedSyncType, setSyncType] = useState(
    syncOptions[0]);
  const [selectedSyncGroups, setSyncGroups] = useState();
  const [selectedTenant, setSelectedTenant] = useState({});
  const navigate = useNavigate();
  const { items, requestSort, sortConfig } = useSortableData(users);
  const ref = useRef();
  const auth = useContext(AuthContext);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [exclusions, setExclusions] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const [initialGroups, setInitialGroups] = useState([]);
  const [syncTypes, setSyncTypes] = useState(syncOptions);
  const [step, setStep] = useState(1);
  const [azureGroups, setAzureGroups] = useState({});

  const closeModal = () => {
    setAzureGroups({});
    setStep(1)
    setSyncModal(false);
  }

  const getGroups = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(getGroupsFunction(inputValue));
      }, 2000);
    });

  const getGroupsFunction = async (inputValue) => {
    try {
      if (token && selectedTenant.id && selectedSyncType.value) {
        const fetched = await requestWithSecondaryLoading(
          `/back_office/api/groups/sync_group_list_azure?groupQuery=${inputValue}&tenantId=${selectedTenant?.id}&syncType=${selectedSyncType?.value}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const data = decryptData(fetched)

        return data.map(e => {
          return {
            value: e.id,
            label: e.displayName,
          }
        });
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const syncOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')} users-list-tenant-label`}>
          {label}
        </div>
      </components.Option>
    );
  };

  const syncGroupsVariations = () => {
    return tenantGroups?.map((e) => {
        return { value: e.id, label: e.name, id: e.id };
      });
  };

  const handleSyncGroupsChange = (data) => {
    setSyncGroups(data);
    const groupIds = data?.map((e) => e.value);
    const exclusionsEmails = exclusions.map(e => e.value)
    const groupUsers = getSelectedUsers(groupIds);
    const groupUsersEmails = groupUsers.map(e => e.email);

    const updatedExclusions = exclusions.filter(e => groupUsersEmails.includes(e.value))
    setExclusions(updatedExclusions);
    setSelectedMembers(groupUsers.filter((e) => !updatedExclusions.includes(e.email)));
  };

  const changeGroupMapping = async () => {
    const selectedData = {
      selectedUsersGroups: selectedSyncGroups,
      destGroup: selectedOptions.label,
      tenantName: selectedTenant.label,
      syncType: selectedSyncType.value,
      exclusionsEmails: exclusions.map((e) => e.value),
    };
    if (token) {
      const data = encryptData(selectedData);
      const response = await request(
        `/back_office/api/groups/create/groups_map`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      auth.showToastMessage(response.error, response.message);
      if (!response.error) {
        closeModal();
      }
    }
  };

  const getExclusionUsers = (exclusions) => {
    const excludedEmails = exclusions?.map((e) => e.userEmail);
    const excludedUsers = allUsers.filter((u) =>
      excludedEmails?.includes(u.email)
    );

    const options = excludedUsers.map((u) => {
      return {
        value: u.email,
        label: `${u.firstName} ${u.secondName} <${u.email}>`,
        key: u.id,
      };
    });

    return options;
  };

  const addExclusions = async (groupName, users) => {
    try {
      const payload = {
        selectedUsers: users.map((u) => u.value),
        tenant: { name: selectedTenant.label, id: selectedTenant.id },
      };

      if (token) {
        const fetched = await request(
          `/back_office/api/groups/${groupName
            .split(' ')
            .join('-')}/exclusions`,
          'POST',
          payload,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptExclusions = decryptData(fetched);
        const tenantExclusions = decryptExclusions.filter(
          (e) => e.tenantName === selectedTenant.label
        );
        const exclusionOptions = getExclusionUsers(tenantExclusions);
        setExclusions(exclusionOptions);
      }
    } catch (error) { }
  };

  const fetchSyncGroupList = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          `/back_office/api/groups/sync_group_list`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptGroups = decryptData(fetched);
        setSyncGroupsOptionList(decryptGroups);

        return decryptGroups;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const getAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/user/get_all_tenants',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptTenants = decryptData(fetched);
        setTenants(decryptTenants.filter((t) => t.type !== 'csv'));
        const syncTypesOptions = decryptTenants.map((t) => t.type);
        const syncOptions = syncTypes.filter((t) =>
          syncTypesOptions.includes(t.value)
        );
        setSyncTypes(syncOptions);
        setSyncType(syncOptions[0]);

        const firstTenant = decryptTenants?.filter(
          (t) => t.type === syncOptions[0].value
        )[0];

        handleTenantChange({
          value: firstTenant?.name.split(' ').join('-'),
          label: firstTenant?.name,
          id: firstTenant?.id,
        });

        return tenants;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getGroupsForTenant = (tenantName, syncType) => {
    const groups = syncGroups[syncType]?.find(
      (l) => l.tenantName === tenantName
    )?.userGroups;

    return groups;
  };

  const azureTenants = tenants?.filter((t) => t.type === 'azure');
  const googleTenants = tenants?.filter((t) => t.type === 'google');
  const tenantGroups = getGroupsForTenant(selectedTenant?.label, selectedSyncType?.value)

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setSyncGroups([]);
    setSelectedMembers([]);
    setExclusions([]);
  };

  const syncGroupsOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')} users-list-tenant-label`}>
          {label}
        </div>
      </components.Option>
    );
  };

  const azureTenantsArray = azureTenants?.map((e, i) => {
    return { value: e.name, label: e.name, id: e.id };
  });
  const googleTenantsArray = googleTenants?.map((e, i) => {
    return { value: e.name, label: e.name, id: e.id };
  });

  const getSelectedUsers = (groupsIds) => {
    const selectedGroups = tenantGroups?.filter((g) =>
      groupsIds?.includes(g.id)
    );

    const usersEmails = selectedGroups
      ?.map((g) => g.members)
      .flat()
      .map((m) => m.mail)

    const groupsUsers = allUsers?.filter((u) => usersEmails?.includes(u.email));

    return groupsUsers;
  };

  const getUsersOptions = (groupsIds = []) => {
  if (selectedSyncType.value === 'azure') {
      const userEmails = selectedSyncGroups?.map((g) => azureGroups[g.value]).flat();
      const uniqueEmails = [...new Set(userEmails)];
      const uniqueUsers = allUsers?.filter((u) => uniqueEmails?.includes(u.email));
      const options = uniqueUsers?.map((u) => {
        return {
          value: u.email,
          label: `${u.firstName} ${u.secondName} <${u.email}>`,
          key: u.email,
        };
      });

      return options;
    }
        const groupsUsers = getSelectedUsers(groupsIds);
        if (groupsUsers.length) {
          const options = groupsUsers?.map((u) => {
            return {
              value: u.email,
              label: `${u.firstName} ${u.secondName} <${u.email}>`,
              key: u.email,
            };
          });
      
          return options;
        }

    const options = allUsers?.map((u) => {
      return {
        value: u.email,
        label: `${u.firstName} ${u.secondName} <${u.email}>`,
        key: u.email,
      };
    });

    return options;
  };

  const tenantsOption = (props) => {
    const { label } = props.data;

    return (
      <components.Option {...props}>
        <div className={`users-list-tenant-label`}>{label}</div>
      </components.Option>
    );
  };

  const handleSyncTypeChange = (data) => {
    setSyncType(data);
    handleTenantChange(
      data.value === 'azure' ? azureTenantsArray[0] : googleTenantsArray[0]
    );
    setExclusions([]);
  };

  const handleEditExclusionsChange = (data) => {
    setExclusions(data);
    const exlusionEmails = data.map((e) => e.value);
    const users = getSelectedUsers(selectedSyncGroups?.map((e) => e.value));
    setSelectedMembers(users.filter((e) => !exlusionEmails.includes(e.email))
    );
  };

  const customStyles = {
    content: {
      padding: '0',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0px 2px 8px rgba(52, 50, 50, 0.15)',
      borderRadius: '4px',
      overflow: 'visible',
    },
  };

  const fetchGroupList = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/groups/group_list',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptGroups = decryptData(fetched);
        setOptionList(decryptGroups);

        return decryptGroups;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const submitHandler = async (selectedUsers) => {
    try {
      const selectedIds = selectedUsers ? selectedUsers : selectedRows;
      const data = encryptData(selectedIds);
      const response = await request(
        `/back_office/api/groups/${selectedOptions?.label
          ?.split(' ')
          .join('-')}`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      showToastMessage(response.error, response.message);
      setSelectedOptions([]);
      setSelectedRows([]);
      navigate(
        `/back_office/groups/${selectedOptions?.label?.split(' ').join('-')}`
      );
    } catch (error) { }
  };

  const addUsers = async (preparedProspects) => {
    try {
      const data = encryptData(preparedProspects);
      if (token) {
        await request(
          `/back_office/api/user/createUsers`,
          'POST',
          { data },
          {
            Authorization: `Bearer ${token}`,
          }
        );
        return users;
      }
    } catch (error) { }
  };

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    let preparedProspects = [];

    if (file) {
      Papa.parse(file, {
        complete: async function (results) {
          let parsedResults = [];
          for (let i = 1; i < results.data.length - 1; i++) {
            let resultsItem = {};
            for (let j = 0; j < results.data[0].length; j++) {
              const entries = Object.fromEntries([
                [results.data[0][j], results.data[i][j]],
              ]);
              resultsItem = { ...resultsItem, ...entries };
            }
            parsedResults.push(resultsItem);
          }
          const emails = [];
          for (const entry of parsedResults) {
            if (!entry['First Name']) {
              const res = {
                warning: 'There is no "First Name" field in the CSV file!',
              };
              return showToastMessage(res.warning);
            }
            if (!entry['First Name'].match(/^[\w- .]{1,50}$/)) {
              const res = {
                warning: `First Name in user: ${entry['First Name'] + ' ' + entry['Last Name']
                  } is not valid!`,
              };
              return showToastMessage(res.warning);
            }
            if (!entry['Last Name']) {
              const res = {
                warning: 'There is no "Last Name" field in the CSV file!',
              };
              return showToastMessage(res.warning);
            }
            if (!entry['Last Name'].match(/^[\w- .]{1,50}$/)) {
              const res = {
                warning: `Last Name in user: ${entry['First Name'] + ' ' + entry['Last Name']
                  } is not valid!`,
              };
              return showToastMessage(res.warning);
            }
            if (!entry['Email Address']) {
              const res = {
                warning: 'There is no "Email Address" field in the CSV file!',
              };
              return showToastMessage(res.warning);
            }
            if (
              !entry['Email Address'].match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
            ) {
              const res = {
                warning: `Email Address in user: ${entry['First Name'] + ' ' + entry['Last Name']
                  } is not valid!`,
              };
              return showToastMessage(res.warning);
            }

            // if (emails.includes(entry['Email Address'])) continue;
            emails.push(entry['Email Address']);
            preparedProspects.push({
              first_name: entry['First Name'],
              last_name: entry['Last Name'],
              email: entry['Email Address'],
            });
          }

          const users = await addUsers(preparedProspects);
          const groupUsers = users
            .filter((u) => emails.includes(u.email))
            .map((u) => u.id);
          await submitHandler(groupUsers);

          setFile(null);
        },
      });
    }
  };

  const getInitialGroups = useCallback(
    async () => {
      try {
        if (token && selectedTenant.id && selectedSyncType.value === 'azure') {
          const fetched = await requestWithSecondaryLoading(
            `/back_office/api/groups/sync_group_list_azure?groupQuery=${inputValue}&tenantId=${selectedTenant?.id}&syncType=${selectedSyncType.value}&limit=999`,
            'GET',
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );
          const data = decryptData(fetched)

          const groups = data.map(e => {
            return {
              value: e.id,
              label: e.displayName,
            }
          });
          setInitialGroups(groups);
        }
      } catch (error) { }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [token, request, selectedTenant?.id]);
    
  useEffect(() => {
    getInitialGroups();
  }, [getInitialGroups]);

  useEffect(() => {
    fetchGroupList();
  }, [fetchGroupList]);

  useEffect(() => {
    fetchSyncGroupList();
  }, [fetchSyncGroupList]);

  useEffect(() => {
    if (filterValue.length) {
      setUsers(() =>
        allUsers.filter(
          (u) =>
            String(u.id).includes(filterValue) ||
            u.email?.includes(filterValue?.toLowerCase()) ||
            [u.firstName?.toLowerCase(), u.secondName?.toLowerCase()]
              .join(' ')
              ?.includes(filterValue?.toLowerCase()) ||
            [u.secondName?.toLowerCase(), u.firstName?.toLowerCase()]
              .join(' ')
              ?.includes(filterValue?.toLowerCase()) ||
            u.firstName?.toLowerCase()?.includes(filterValue?.toLowerCase()) ||
            u.secondName?.toLowerCase()?.includes(filterValue?.toLowerCase())
        )
      );
    } else {
      setUsers(allUsers);
    }
  }, [filterValue, allUsers]);

  const getGroupMembers = useCallback(async () => {
    try {
      if (selectedSyncType.value === 'azure') {
        for (const group of selectedSyncGroups) {
        const groupId = group.value;
        if (!azureGroups[groupId]) {
          const fetched = await requestWithSecondaryLoading(
            `/back_office/api/groups/get_groups_members_azure?groupId=${groupId}&tenantId=${selectedTenant?.id}`,
            'GET',
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );
          const data = decryptData(fetched)

          setAzureGroups((prev) => {return {...prev, [groupId]: data}});
        }
      }}

    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, selectedTenant?.id, selectedSyncGroups]);
  
  useEffect(() => {
    getAllTenants();
  }, [getAllTenants]);

  useEffect(() => {
    getGroupMembers();
  }, [getGroupMembers]);

  if (loading) {
    return <Loader />;
  }

  const Option = (props) => {
    const { label, caption } = props.data;

    return (
      <components.Option {...props}>
        <div className="new-group-users-table-label">{label}</div>
        <div className="group-caption">{caption}</div>
      </components.Option>
    );
  };

  return (
    <>
      {!loading && card === 1 && (
        <div className="users-main">
          <div>
            <p className="new-group-users-table-add-title">Add group</p>
            <h4 className="new-group-users-table-add-subtitle">Select group</h4>

            <div className="group-select">
              <Select
                id="select"
                options={optionList}
                components={{ Option }}
                className="group-multiselect"
                placeholder="Select group"
                value={selectedOptions}
                onChange={handleSelect}
                isSearchable={true}
                closeMenuOnSelect={true}
                styles={colourStyles}
              />
            </div>
            <h4 className="new-group-users-table-add-subtitle">
              Select users for the group
            </h4>

            <div className="input-container">
              <input
                id="users"
                name="users"
                placeholder="Search users..."
                value={filterValue}
                className="group-input-edit multidelect"
                onChange={(e) => setFilterValue(e.target.value)}
              />

              <span>OR</span>

              {!tenants.length ? (
                <CustomizedTooltipDisabled
                  position={'top'}
                  text1={'Will be active after first sync with'}
                  text2={'Azure Active Directory or'}
                  text3={'Google Workspace'}
                  button={
                    <button
                      className="group-button-secondary"
                      disabled={!tenants.length}
                      onClick={() => setSyncModal(true)}
                    >
                      Sync with integration
                    </button>
                  }
                />
              ) : (
                <button
                  className="group-button-secondary"
                  disabled={!tenants.length}
                  onClick={() => setSyncModal(true)}
                >
                  Sync with integration
                </button>
              )}

              {/* TODO: decide how to be with CSV tenants when users to group are added */}
              {/* <span>OR</span>

              <button
                className="group-button-secondary"
                disabled={selectedRows.length}
                onClick={() => setCard(2)}
              >
                Upload CSV file
              </button> */}
            </div>

            <button
              className="group-button group-button-simple"
              onClick={() => submitHandler(selectedRows)}
              disabled={!selectedRows.length || !selectedOptions}
              type="submit"
            >
              Add users to the group
            </button>
            {!!selectedRows.length && (
              <>
                <div className="group-selected-bar">
                  <button
                    className={
                      selectedUsersShown
                        ? 'email-button-active'
                        : 'email-button'
                    }
                    onClick={() => setSelectedUsersShown((prev) => !prev)}
                  >
                    {selectedRows.length < 2
                      ? `${selectedRows.length} user selected`
                      : `${selectedRows.length} users selected`}
                  </button>
                  <button
                    className="secondary-button"
                    onClick={() => setSelectedRows([])}
                  >
                    Clear all
                  </button>
                </div>

                {!!selectedUsersShown && (
                  <div className="emails-container">
                    {selectedRows.map((selectedId, i) => (
                      <div className="email-container" key={i}>
                        {allUsers.find((u) => u.id === selectedId)?.email}
                        <span className="email-container-img">
                          <img
                            onClick={() =>
                              setSelectedRows((prev) =>
                                prev.filter((id) => id !== selectedId)
                              )
                            }
                            src={cross}
                            alt="delete"
                          ></img>
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <div className="audit table">
              <table className="new-group-users-table-table">
                <thead className="table-th">
                  <tr className="new-group-users-table-not-clickable new-group-users-table-tr">
                    <th></th>
                    <th>User ID</th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('email')}
                        className={getClassNamesFor('email', sortConfig)}
                      >
                        Email
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('firstName')}
                        className={getClassNamesFor('firstName', sortConfig)}
                      >
                        First name
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('secondName')}
                        className={getClassNamesFor('secondName', sortConfig)}
                      >
                        Last name
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('tenantName')}
                        className={getClassNamesFor('tenantName', sortConfig)}
                      >
                        Tenant
                      </button>
                    </th>
                    <th>Sync method</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {items.map((u, i) => {
                    if (i < page * itemsOnPage) {
                      return (<tr
                        key={u.id}
                        onClick={() =>
                          setSelectedRows((prevState) =>
                            prevState.includes(u.id)
                              ? prevState.filter((id) => id !== u.id)
                              : [...prevState, u.id]
                          )
                        }
                        className={'new-group-users-table-clickable-row new-group-users-table-tr '.concat(
                          selectedRows.includes(u.id) ? 'group-selected' : ''
                        )}
                      >
                        <td>
                          <div className="user-checkbox-action">
                            <label className="checkbox-label">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={selectedRows.includes(u.id)}
                                onChange={() =>
                                  setSelectedRows((prevState) =>
                                    prevState.includes(u.id)
                                      ? prevState.filter((id) => id !== u.id)
                                      : [...prevState, u.id]
                                  )
                                }
                              />
                              <span className="checkbox-span"></span>
                            </label>
                          </div>
                        </td>
                        <td className="group-td-email">{u.id}</td>
                        <td className="group-td-email">{u.email}</td>
                        <td>{u?.firstName}</td>
                        <td>{u?.secondName}</td>
                        <td className="group-td-email">{u.tenantName}</td>
                        <td className="list-td-email">
                          <span>
                            {u.syncType && (
                              <img
                                src={
                                  u.syncType === 'azure'
                                    ? azure
                                    : u.syncType === 'google'
                                      ? google
                                      : u.syncType === 'csv'
                                        ? csv
                                        : ''
                                }
                                alt="Logo"
                                className="list-td-email-icon"
                              ></img>
                            )}
                            {u.syncType}
                          </span>
                        </td>
                      </tr>)
                    }
                    return null
                  })}
                </tbody>
              </table>
              {page * itemsOnPage < items.length && <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  className='list-button'
                  onClick={() => {
                    setPage((prev) => prev + 1)
                  }}
                  disabled={loading}
                  type="submit"
                >
                  Download more
                </button>
              </div>
              }
            </div>
          </div>
        </div>
      )}
      {!loading && card === 2 && (
        <div className="csv-card">
          <p className="new-group-users-table-add-title">Add group</p>
          <h4 className="new-group-users-table-add-subtitle">Select group</h4>
          <div className="group-select">
            <Select
              id="select"
              options={optionList}
              components={{ Option }}
              className="group-multiselect"
              placeholder="Select group"
              value={selectedOptions}
              onChange={handleSelect}
              isSearchable={true}
              closeMenuOnSelect={true}
              styles={colourStyles}
            />
          </div>
          <div className="csv-card-title">
            <p className="details-help">
              <span>Need help with a CSV file? </span>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.phishfirewall.com/onboarding-instructions/step-two/#toggle-id-3"
              >
                View our setup instructions.
              </a>
            </p>
            <p className="details-help">
              <a
                target="_blank"
                rel="noreferrer"
                href="/csv_template.csv"
                download
              >
                Download CSV template
              </a>
            </p>
            <h2>CSV file uploading</h2>
          </div>
          <form onSubmit={() => (this.refs.file.value = '')}>
            <div className="new-users-group-input">
              <input
                type="file"
                accept=".csv"
                onChange={handleOnChange}
                ref={ref}
              />
            </div>
            <br />
            <button
              onClick={(e) => {
                handleOnSubmit(e);
              }}
              className="button-submit"
              disabled={
                !fileName?.endsWith('.csv') ||
                file?.size > 10485760 ||
                !selectedOptions
              }
            >
              <img src={submit} alt="submit"></img>
              Submit
            </button>
          </form>
        </div>
      )}

      {!!tenants.length && <Modal
        isOpen={syncModal}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form className="form modal-form-sync" style={{ padding: '24px' }}>
          <div
            className="title"
            style={{
              marginBottom: '12px',
              fontSize: '20px',
              textAlign: 'left',
            }}
          >
            <span
              className="modal-title"
              style={{ fontSize: '20px', color: '#343232' }}
            >
              Sync with integration groups
            </span>
            <br></br>
          </div>
          {step === 1 && <div
            className="card-content"
            style={{ width: '100%', padding: '0 8px' }}
          >
            <div className="new-group-select">
              <label className="userslist-select-label">
                Choose sync method
                <Select
                  id="select"
                  options={syncTypes}
                  components={{ syncOption }}
                  placeholder="Select sync type"
                  value={selectedSyncType}
                  onChange={handleSyncTypeChange}
                  isSearchable={true}
                  styles={colourStyles}
                />
              </label>
            </div>
            <div className="new-group-select">
              <label className="userslist-select-label">
                Choose tenant
                <Select
                  id="select"
                  options={
                    selectedSyncType?.value === 'azure'
                      ? azureTenantsArray
                      : googleTenantsArray
                  }
                  components={{ tenantsOption }}
                  placeholder="Select sync type"
                  value={selectedTenant}
                  onChange={handleTenantChange}
                  isSearchable={true}
                  styles={colourStyles}
                />
              </label>
            </div>
            <strong>FROM</strong>
            <div className="new-group-select">
              <label className="userslist-select-label">
                Choose group
                {selectedSyncType.label ? ' in ' + selectedSyncType.label : ''}
                {selectedSyncType.value === 'azure' ? (
                <AsyncSelect
                  id="select"
                  placeholder="Type to find group"
                  value={selectedSyncGroups}
                  onChange={handleSyncGroupsChange}
                  isSearchable={true}
                  inputValue={inputValue}
                  onInputChange={(e) => setInputValue(e)}
                  styles={colourStyles}
                  loadOptions={getGroups}
                  isLoading={secondaryLoading}
                  isMulti
                  closeMenuOnSelect={true}
                  optionComponent={GroupsOption}
                  defaultOptions={initialGroups}
                  cacheOptions={true}
                />
              ) : (
                <ReactSelect
                  id="select"
                  options={syncGroupsVariations()}
                  classNames={'group-multiselect'}
                  optionComponent={GroupsOption}
                  placeholder="Select group"
                  value={selectedSyncGroups}
                  onChange={handleSyncGroupsChange}
                  isSearchable={true}
                  isLoading={syncGroups?.[selectedSyncType.value] === undefined}
                  styles={colourStyles}
                  isMulti
                />)}
              </label>
            </div>
            <strong>TO</strong>
            <div className="new-group-select">
              <label className="userslist-select-label">
                <Select
                  id="select"
                  options={optionList}
                  components={{ Option }}
                  placeholder="Select group"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  styles={colourStyles}
                />
              </label>
            </div>
          </div>}

          {step === 2 &&
            <div className="new-group-select">
              <label className="userslist-select-label">
                Excluded users
                <Select
                  id="select"
                  options={getUsersOptions(
                    selectedSyncGroups?.map((e) => e.value)
                  )}
                  components={{ syncGroupsOption }}
                  placeholder="Select users to exclude"
                  value={exclusions}
                  onChange={handleEditExclusionsChange}
                  isSearchable={true}
                  styles={colourStyles}
                  isMulti
                />
              </label>
            </div>}

          {/* <div className="content-delete">
            <>
              {!!selectedMembers?.length && (
                <span className="card-title">Add this users</span>
              )}
              {!selectedMembers?.length && !!selectedSyncGroups?.length && (
                <span className="modal-warning">
                  <img src={alert} alt="edit"></img>
                  There are no active PhishFirewall users in this group.
                  <br></br>
                  Are you sure you have the correct group?
                </span>
              )}
              <br></br>
              <div>
                <div className="delete-scroll">
                  {selectedMembers?.map((user, i) => {
                    return (
                      <div key={user.id}>
                        {i + 1}. {user?.firstName} {user?.secondName}
                        <strong>
                          {'<'}
                          {user.email}
                          {'>'}
                        </strong>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          </div> */}
          {step === 1 && <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            <button
              className="group-button-secondary"
              onClick={() => closeModal()}
              >
              Cancel
            </button>

            <button
              className="group-button group-button-simple"
              onClick={() => setStep(2)}
              disabled={!selectedOptions || !selectedSyncGroups?.length}
            >
              Next
            </button>
          </div>}

          {step === 2 && <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            <button
              className="group-button-secondary"
              onClick={() => setStep(1)}
              >
            Back to group selection
            </button>

            <button
              className="group-button group-button-simple"
              onClick={async () => {
                await changeGroupMapping();
                await addExclusions(selectedOptions.label, exclusions);
                closeModal();
                navigate(
                  `/back_office/groups/${selectedOptions?.label?.split(' ').join('-')}`)
              }}
              disabled={!selectedOptions || !selectedSyncGroups?.length}
            >
              Submit
            </button>
          </div>}
        </form>
      </Modal>}
    </>
  );
};
