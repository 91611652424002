import React, { useState, useContext, useRef, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select, { components } from "react-select";
import Papa from "papaparse";
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import { Loader } from '../Loader/Loader';
import { languageOptions } from '../../config/config';
import { useCrypto } from '../../hooks/crypto.hook';
import submit from '../../images/icons/Check.svg';
import colourStyles from '../../styles/colour-style';
import './CsvUpload.css'

export const CsvUploadEdit = () => {
  const { token, showToastMessage, fetchUser } = useContext(AuthContext);
  const { loading, request } = useHttp();
  const { encryptData } = useCrypto();
  const ref = useRef();
  const { id } = useParams();

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [csvLoading, setCsvLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [tenantName, setTenantName] = useState('');
  const [tenant, setTenant] = useState({});
  const [lang, setLang] = useState('');

  const changeEditHandler = (event) => {
    setTenantName(event.target?.value.trim())
  };
  
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0]?.name)
  };

  const handleLangChange = (data) => {
    setLang(data);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  }

  const navigate = useNavigate();

  const getTenant = useCallback(async () => {
    try {
      if (token) {
        const tenant = await request(`/back_office/api/user/csv_tenant/${id}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        });
        setTenant(tenant);
        setLang(tenant.language ? { value: tenant.language?.toLowerCase(), label: tenant.language } : null);
        setTenantName(tenant.name);

        return tenant;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    getTenant();
  }, [getTenant]);

  const languageList = languageOptions.map((v, i) => { return { value: v?.toLowerCase(), label: v, id: i } })
  const languageOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`langTypes-label-${value.toString().split(' ').join('-')}`}>{label}</div>
      </components.Option>
    );
  };
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    let preparedUsers = [];
    try {
      if (file) {
        Papa.parse(file, {
          complete: async function (results) {
            let parsedResults = []
            for (let i = 1; i < results.data.length; i++) {
              if (results.data[i].length === 1 && results.data[i][0] === '') {
                continue;
              }
              let resultsItem = {}
              for (let j = 0; j < results.data[0].length; j++) {
                const entries = Object.fromEntries([[results.data[0][j], results.data[i][j]]])
                resultsItem = { ...resultsItem, ...entries }
              }
              parsedResults.push(resultsItem);
            }

            const emails = [];
            for (const entry of parsedResults) {
              if (!entry['First Name']) {
                const res = { warning: 'There is no "First Name" field in the CSV file!' }
                return showToastMessage(res.warning);
              }
              if (!entry['First Name'].match(/^[\w- .]{1,50}$/)) {
                const res = { warning: `First Name in user: ${entry['First Name'] + ' ' + entry['Last Name']} is not valid!` }
                return showToastMessage(res.warning);
              }
              if (!entry['Last Name']) {
                const res = { warning: 'There is no "Last Name" field in the CSV file!' }
                return showToastMessage(res.warning);
              }
              if (!entry['Last Name'].match(/^[\w- .]{1,50}$/)) {
                const res = { warning: `Last Name in user: ${entry['First Name'] + ' ' + entry['Last Name']} is not valid!` }
                return showToastMessage(res.warning);
              }
              if (!entry['Email Address']) {
                const res = { warning: 'There is no "Email Address" field in the CSV file!' }
                return showToastMessage(res.warning);
              }
              if (!entry['Email Address'].match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )) {
                const res = { warning: `Email Address in user: ${entry['First Name'] + ' ' + entry['Last Name']} is not valid!` }
                return showToastMessage(res.warning);
              }

              // if (emails.includes(entry['Email Address'])) continue;
              emails.push(entry['Email Address']);
              preparedUsers.push({
                displayName: entry['First Name'] + ' ' + entry['Last Name'],
                givenName: entry['First Name'],
                surname: entry['Last Name'],
                mail: entry['Email Address'],
                country: entry['Location'] || entry['Location(optional)'], // ???
                city: entry['Location'] || entry['Location(optional)'],
                department: entry['Department'] || entry['Department(optional)'],
                language: entry['Language'] || entry['Language(optional)']|| lang?.label
              })
            };

            setCsvLoading(true);

            const data = encryptData(preparedUsers);
            const trimTenantName = tenantName.trim();
            const res = await request(`/back_office/api/user/csv_create?file=${fileName}&tenant=${trimTenantName}&language=${lang?.label}&edit=true&tenant_id=${tenant?.id}`, 'POST', { data }, {
              Authorization: `Bearer ${token}`
            });

            showToastMessage(res.error, res.message, res.warning);
            setCsvLoading(false);
            setFile(null);
            fetchUser();
          }
        });
      }

      const data = encryptData({id: tenant?.id, tenantName, language: lang?.label})
      setEditLoading(true);
      const res = await request('/back_office/api/user/csv_edit', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })
      setEditLoading(false);
      showToastMessage(res.error, res.message, res.warning);

      if (res.message) {
        fetchUser();
        navigate('/back_office/user/users_database/csv');
      }
    } catch (error) {

    }
  };

  if (loading) {
    return <Loader />
  }

  return (
    <> {!loading &&
      <div>
        <p className="details-help">
          <span>Need help setting up sync from a CSV file? </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.phishfirewall.com/onboarding-instructions/step-two/#toggle-id-3"
          >
            View our setup instructions.
          </a>
        </p>
        <p className="details-help">
          <a
            target="_blank"
            rel="noreferrer"
            href= "/csv_template.csv"
            download
          >
            Download CSV template
          </a>
        </p>

        <div className='csv-card'>
          <div className='csv-card-title'>
            <h2>CSV file editing</h2>
            <p>Set up integration using CSV file with the list of users, who you want to train.</p>
          </div>

          <form onSubmit={onSubmit}>
            <div className='csv-form input-field-tenant'>
              <div className="csv-input-field">
                <label htmlFor="tenantName" className="csv-input-label input-label-required">Tenant Name</label>
                <input
                  id="tenantName"
                  name="tenantName"
                  placeholder="Enter Tenant Name"
                  className="validate input-edit"
                  defaultValue={tenantName}
                  onChange={changeEditHandler}
                />
              </div>
              <div></div>
              <div className="csv-file-input">
              <input
                type="file"
                accept=".csv"
                onChange={handleOnChange}
                ref={ref}
                disabled={csvLoading}
              />
              </div>
            </div>

            <div className="input-field-tenant">
              <div className='userslist-select'>
                <label className='userslist-select-label input-label-required'>
                Default user language
                </label>
                  <p className="input-description">
                  If no language is specified for user in the CSV, the default language will be applied.
                  </p>
                <Select
                  id='select'
                  className='validate multiselect-azure'
                  options={languageList}
                  components={{ languageOption }}
                  placeholder="Select language"
                  value={lang}
                  onChange={handleLangChange}
                  isSearchable={true}
                  styles={colourStyles}
                />
              </div>
            </div>

            <button
              onClick={(e) => {
                handleOnSubmit(e)
              }}
              className="button-submit"
              disabled={(file ? (!fileName?.endsWith('.csv') || file?.size > 10485760) : false) || !lang?.label || !tenantName || csvLoading}
            >
              <img src={submit} alt="submit"></img>
              Submit
            </button>
          </form>
        </div>
        {(csvLoading && <Loader />) || (editLoading && <Loader />)}
      </div>
      }
    </>
  );
}
