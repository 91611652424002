import React, { useState, useCallback, useEffect, useContext } from 'react';
import cn from 'classnames';
import moment from 'moment';
import Select, { components } from "react-select";
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useSortableData } from '../../hooks/sort.hook';
import { Loader } from '../../components/Loader/Loader';
import { AuthContext } from '../../context/AuthContext';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import { useCrypto } from '../../hooks/crypto.hook';
import { itemsOnPage } from '../../config/config';
import { CustomizedTooltipDisabled } from '../Tooltips/Tooltip';
import colourStyles from '../../styles/colour-style';
import ReactSelect from '../ReactSelect/ReactSelect';
import { AllUsersOption, EmailTypeOption } from '../ReactSelect/ReactSelectOption';
import { Pagination } from '../Pagination/Pagination';
import './MailingHistory.css';

export const MailingHistory = () => {
  const { loading, request } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const { showToastMessage, user, allUsers } = useContext(AuthContext);

  const [mailingHistory, setMailingHistory] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState({ value: 'all', label: "All", id: 0, syncType: null });
  const [emailTypes, setEmailTypes] = useState([]);
  const [emailType, setEmailType] = useState(null);

  const { items, requestSort, sortConfig } = useSortableData(mailingHistory);

  const fetchData = useCallback(async (selectedUser, tenant, emailType) => {
    try {
      if (token) {
        const data = encryptData({
          userId: selectedUser?.value,
          tenant: tenant.label,
          syncType: tenant.syncType,
          emailType: emailType?.value
        });
        const fetched = await request(`/back_office/api/user/sync_mailing_history/${page}`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })

        const decryptHistory = decryptData(fetched);
        setMailingHistory(decryptHistory?.rows);
        setCount(decryptHistory?.count);
        !emailType && setEmailTypes(decryptHistory?.emailTypes);

        return decryptHistory;
      }
    } catch (error) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, page]);

  useEffect(() => {
    const usersTenants = allUsers.map(el => ({
      tenantName: el.tenantName,
      syncType: el.syncType
    }));
    const uniqueObjects = [...new Set(usersTenants.map(JSON.stringify))].map(JSON.parse).sort((a, b) => a.tenantName.localeCompare(b.tenantName));
    const selectTenantArray = [{tenantName: 'All', syncType: null}, ...uniqueObjects]
    const tenantList = selectTenantArray.map((v, i) => { return { value: v?.tenantName?.toLowerCase(), label: v?.tenantName, id: i, tenant: true, syncType: v?.syncType } })

    setTenants(tenantList)
  }, [allUsers]);

  const exportMailingHistoryCsv = async (selectedUser, tenant, emailType) => {
    try {
      if (token) {
        setDownloadLoading(true);

        const data = encryptData({
          userId: selectedUser?.value,
          tenant : tenant.label,
          syncType: tenant.syncType,
          emailType: emailType?.value
        });
        const response = await fetch('/back_office/api/user/download_mailing_history', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data })
        });

        if (!response.ok) {
          showToastMessage('Failed to export CSV file!');
          setDownloadLoading(false);
          throw new Error('Download failed');
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;

        if (selectedUser) a.download = `mailing_history__UserID-${selectedUser?.value}__` + new Date().toISOString().split('T')[0] + '.csv';
        else a.download = 'mailing_history_'+ new Date().toISOString().split('T')[0] + '.csv';
        a.textContent = 'Download CSV';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setDownloadLoading(false);
        showToastMessage(null, 'CSV file with Mailing history downloaded successfully');
      }
    } catch (error) {
      setDownloadLoading(false);
      showToastMessage('Failed to export CSV file!');
    }
  };

  const allUsersFilteredByTenant = allUsers.filter(user => selectedTenant.label !== 'All'
    ? user.tenantName === selectedTenant.label && user.syncType === selectedTenant.syncType
    : user
  );
  const allUsersOptionList = allUsersFilteredByTenant.map(user => (
    { value: user.id, label: user.email, caption: user.firstName + ' ' + user.secondName, allUsers: true }
  ))

  const emailTypesOptionList = emailTypes?.map(type => (
    {
      value: type,
      label: type.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim().replace(/\s+/g, ' ').replace(/^./, str => str.toUpperCase())
    }
  ))

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setSelectedUser(null);
    setEmailType(null);
    setPage(1);
  };

  const handleUserChange = (data) => {
    setSelectedUser(data);
    setEmailType(null);
    setPage(1);
  };

  const handleEmailChange = (data) => {
    setEmailType(data);
    setPage(1);
  };

  const handlePageChange = (page) => {
    setTimeout(() => {
      setPage(page);
    }, 100)
  };

  useEffect(() => {
    fetchData(selectedUser, selectedTenant, emailType);
  }, [fetchData, selectedTenant, selectedUser, emailType]);

  const Option = (props) => {
    const { value, label, caption, tenant, allUsers, syncType } = props.data;

    return (
      <components.Option {...props}>
        <div className={cn(`syncTypes-label-${value.toString().split(' ').join('-')} syncTypes-label-${syncType}`, {
            'all-users-label': allUsers,
            'users-list-tenant-label': tenant
          })}
        >
          {label} {syncType ? `(${syncType})` : ''}
        </div>
        <div className='caption'>{caption}</div>
      </components.Option>
    );
  };
  
  return (
    <>
      <div className='history-card'>
        <>
          <div className='actions-table-title'>
            <h3>Mailing list</h3>
            <div className='actions-table-tenant'>
              <p>Tenant:</p>
              <div className={`input-field-actions-tenant input-field-tenant-${selectedTenant.syncType}`}>
                <Select
                  id='select'
                  options={tenants}
                  components={{ Option }}
                  placeholder="Select tenant"
                  className="select-edit-actions"
                  value={selectedTenant}
                  onChange={handleTenantChange}
                  isSearchable={true}
                  styles={colourStyles}
                />
              </div>
            </div>
            <div className={'all-users-select'}>
              <label className='userslist-select-label'>
                <ReactSelect
                  classNames={'multiselect-user'}
                  options={allUsersOptionList}
                  optionComponent={AllUsersOption}
                  placeholder={'User...'}
                  value={selectedUser}
                  onChange={handleUserChange}
                  isSearchable={true}
                  isClearable={true}
                  optionHeight={50}
                  rows={6}
                />
              </label>
            </div>
            <div className={`email-type-select email-type-select-${emailType?.value}`}>
              <label className='userslist-select-label'>
                <ReactSelect
                  classNames={'multiselect-action-type'}
                  options={emailTypesOptionList}
                  optionComponent={ EmailTypeOption }
                  placeholder={'Email Type...'}
                  value={emailType}
                  onChange={handleEmailChange}
                  isSearchable={true}
                  isClearable={true}
                  optionHeight={32}
                  rows={10}
                />
              </label>
            </div>
            <div>
              {user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4 && 
                <CustomizedTooltipDisabled
                  position={'left'}
                  text1={'This feature is available'}
                  text2={'in the Professional'}
                  text3={'and Enterprise plan'}
                />
              }
              {mailingHistory.length
                ? !downloadLoading
                  ? <button
                      className='actions-download'
                      type="button"
                      onClick={() => exportMailingHistoryCsv(selectedUser, selectedTenant, emailType)}
                      disabled={user.paymentPlanId !== 3 && user.paymentPlanId !== 4}
                    >
                      Export csv
                    </button>
                  : <button
                      className='csv-download-loading'
                      type="button"
                    >
                      <span className="mini-loader-csv"></span>
                    </button>
                : <div style={{'width': '120px'}}></div>
              }
            </div>
          </div>
          
          {!loading
            ? !!mailingHistory.length
              ? <table className="history-table">
                  <thead className="table-th-history">
                    <tr className="not-clickable-history tr-grid-history tr-history">
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('firstName')}
                          className={getClassNamesFor('firstName', sortConfig)}
                        >
                          User Name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('email')}
                          className={getClassNamesFor('email', sortConfig)}
                        >
                          User Email
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('segmentName')}
                          className={getClassNamesFor('segmentName', sortConfig)}
                        >
                          Segment Name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('template')}
                          className={getClassNamesFor('template', sortConfig)}
                        >
                          Template Name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('templateSubject')}
                          className={getClassNamesFor('templateSubject', sortConfig)}
                        >
                          Template Subject
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('name')}
                          className={getClassNamesFor('name', sortConfig)}
                        >
                          Email Type
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('sendouttimestamp')}
                          className={getClassNamesFor('sendouttimestamp', sortConfig)}
                        >
                          Sending time
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="table-body table-body-history"
                  >
                    {items.map((a) => (
                      <tr
                        key={a.id}
                        className={"tr-grid-history clickable-row-history tr-history"}
                      >
                        <td>{a?.firstName} {a?.secondName}</td>
                        <td>{a?.email}</td>
                        <td>{a?.segmentName}</td>
                        <td>{a?.template}</td>
                        <td>{a?.templateSubject}</td>
                        <td>{a?.name}</td>
                        <td>{moment(a?.sendouttimestamp).utc().format('MM/DD/YYYY HH:mm:ss')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              : <div>
                  <p className="history-description">
                    There are no mailing history yet.
                  </p>
                </div>
            : <Loader />
          }
        </>
      </div>
      {!!mailingHistory.length && !loading &&
        <Pagination
          className="pagination-bar"
          siblingCount={2}
          currentPage={page}
          totalCount={count}
          pageSize={itemsOnPage}
          onPageChange={handlePageChange}
        />
      }
    </>
  )
}
