import React from 'react';
import { NavLink } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import { useHttp } from '../../hooks/http.hook';
import { useTitle } from '../../hooks/title.hook';
import cn from 'classnames';
import './UsersActions.css';

export const UsersActionsMain = () => {
  useTitle("PhishFirewall | User Actions");

  const { loading} = useHttp();

  return (
    <> {!loading &&
        <div className="actions-main">
          <h2 className="actions-main-title">
            User actions
          </h2>

          <nav className="actions-nav">
            <NavLink
              to="/back_office/activity/users_actions/chart"
              className={({ isActive }) => cn('actions-item',
                { 'actions-item--active': isActive })}
            >
              Action chart
            </NavLink>
          
            <NavLink
              to="/back_office/activity/users_actions/users_actions"
              className={({ isActive }) => cn('actions-item',
                { 'actions-item--active': isActive })}
            >
              User actions
            </NavLink>
          </nav>

          <Outlet/>
        </div>
      }
    </>
  )
}
