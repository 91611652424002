import React from 'react';
import { NavLink } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import { useHttp } from '../../hooks/http.hook';
import { useTitle } from '../../hooks/title.hook';

import cn from 'classnames';
import './MailingHistory.css';

export const MailingHistoryMain = () => {
  useTitle("PhishFirewall | Mailing History");
  const { loading } = useHttp();

  return (
    <> {!loading &&
        <div className="history-main">
          <h2 className="history-main-title">
            Mailing history
          </h2>

          <nav className="history-nav">
            <NavLink
              to="/back_office/activity/mailing_history/chart"
              className={({ isActive }) => cn('history-item',
                { 'history-item--active': isActive })}
            >
              Mailing chart
            </NavLink>
          
            <NavLink
              to="/back_office/activity/mailing_history/mailing_history"
              className={({ isActive }) => cn('history-item',
                { 'history-item--active': isActive })}
            >
              Mailing history list
            </NavLink>

          </nav>
          <Outlet/>
        </div>
      }
    </>
  )
}
