import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import cn from "classnames";
import { useHttp } from '../../hooks/http.hook';
import { Loader } from '../../components/Loader/Loader';
import { useSortableData } from "../../hooks/sort.hook";
import { AuthContext } from "../../context/AuthContext";
import { useCrypto } from "../../hooks/crypto.hook";
import { getClassNamesFor } from '../../common/getClassNamesFor';
import { SetSchedulerModal } from "../../components/SetSchedulerModal/SetSchedulerModal";
import './TenantsList.css';

export const TenantsList = () => {
  const { loading, request } = useHttp();
  const { token } = useContext(AuthContext);
  const { decryptData } = useCrypto();

  const [tenants, setTenants] = useState([]);
  const [tenantName, setTenantName] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editTenant, setEditTenant] = useState({
    tenantId: '',
    tenantName: '',
    type: '',
    scheduledLaunch: null,
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditTenant({
      tenantId: '',
      tenantName: '',
      type: '',
      scheduledLaunch: null,
    });
  };

  const { items, requestSort, sortConfig } = useSortableData(tenants);

  const getAllTenants = useCallback(async () => {
    try {
      if (token) {
        const res = await request('/back_office/api/user/all_tenants', 'GET', null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptRes = decryptData(res);
        setTenants(decryptRes);

        return tenants;
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    getAllTenants();
  }, [getAllTenants]);

  return (
    <div className="tenants-main">
      {!loading
        ? !!tenants.length
          ? <div>
              <div className="tenants-main-description">
                <h5 className="tenants-main-name">All tenants</h5>
                <div className="input-edit-tenants">
                  <input
                    placeholder="Search tenant..."
                    value={tenantName}
                    type='text'
                    onChange={(e) => setTenantName(e.target.value)}
                  />
                  <i
                    role='button'
                    onClick={() => setTenantName('')}
                    className={cn({ 'tenants-clear-disabled': !tenantName })}
                  />
                </div>
              </div>
              <table className="tenants-table">
                <thead className="table-th-tenants">
                  <tr className="not-clickable-tenants tr-grid-tenants tr-tenants">
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('tenantName')}
                        className={getClassNamesFor('tenantName', sortConfig)}
                      >
                        Tenant Name
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('type')}
                        className={getClassNamesFor('type', sortConfig)}
                      >
                        Sync Type
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('scheduledLaunch')}
                        className={getClassNamesFor('scheduledLaunch', sortConfig)}
                      >
                        Launch Date
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('launched')}
                        className={getClassNamesFor('launched', sortConfig)}
                      >
                        Launch Status
                      </button>
                    </th>
                    <th>
                      Set the Launch Date
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body table-body-tenants">
                  {items
                    .filter(t => t.name.toLowerCase().includes(tenantName.toLowerCase()))
                    .map((t) => (
                      <tr
                        key={t.id}
                        className={"tr-grid-tenants clickable-row-tenants tr-tenants"}
                        >
                          <td className={'list-td-tenants'}>{t.name}</td>
                          <td className={`list-td-tenants list-td-tenants-tenant-${t.type}`}>{t.type}</td>
                          <td className='list-td-tenants'>
                            <span className='td-tenants-all-users'>{
                              !!t.scheduledLaunch
                                ? moment(t.scheduledLaunch).format('MM/DD/YYYY')
                                : '-'
                            }</span>
                          </td>
                          <td className='list-td-tenants'>
                            {t.scheduledLaunch
                              ? t.launched
                                ? <div className='tenants-list-td-status-configured'>Launched</div>
                                : <div className='tenants-list-td-status-scheduled'>Scheduled</div>
                              : <div className='tenants-list-td-status-not-defined'>Not defined</div>
                            }
                          </td>
                          <td className='list-td-tenants'>
                            {!t.launched &&
                              <i
                                role='button'
                                onClick={() => {
                                  setEditTenant({
                                    tenantId: t.id,
                                    tenantName: t.name,
                                    type: t.type,
                                    scheduledLaunch: t.scheduledLaunch ? moment(t.scheduledLaunch).format('YYYY-MM-DD') : null,
                                  })
                                  openModal()
                                }}
                              />
                            }
                          </td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>
          : <p className="tenants-description">
              You don't have any created tenants yet.
            </p>
        : <Loader />
      }

      <SetSchedulerModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        editTenant={editTenant}
        setEditTenant={setEditTenant}
        callback={getAllTenants}
      />
    </div>
  )
}
